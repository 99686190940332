import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Flex } from "@adobe/react-spectrum";

import FrameReportScreen from "./screens/FrameReportScreen";
import LuReportScreen from "./screens/LuReportScreen";
import ContactScreen from "./screens/ContactScreen";
import ErrorScreen from "./screens/ErrorScreen";
import FrameCreationScreen from "./screens/FrameCreationScreen";
import FrameIndexScreen from "./screens/FrameIndexScreen";
import FrameRootScreen from "./screens/FrameRootScreen";
import FrameDefinitionScreen from "./screens/FrameDefinitionScreen";
import FrameToFrameRelationScreen from "./screens/FrameToFrameRelationScreen";
import FeScreen from "./screens/FeScreen";
import FeDefinitionScreen from "./screens/FeDefinitionScreen";
import FeRelationScreen from "./screens/FeRelationScreen";
import FrameSummaryScreen from "./screens/FrameSummaryScreen";
import HomeScreen from "./screens/HomeScreen";
import LuCreationScreen from "./screens/LuCreationScreen";
import LuIndexScreen from "./screens/LuIndexScreens";
import LuSearchScreen from "./screens/LuSearchScreen";
import LuSearchResultsScreens from "./screens/LuSearchResultScreen";
import MyFramesScreen from "./screens/MyFramesScreen";
import MyLexicalUnitsScreen from "./screens/MyLexicalUnitsScreen";
import RegisterScreen from "./screens/RegisterScreen";
import WelcomeScreen from "./screens/WelcomeScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import AboutScreen from "./screens/AboutScreen";

import { setBodyBg } from "./utils/dom";
import history from "./utils/history";

import "./App.css";

const AUTHENTICATED_ROUTES = [
	<Route exact path="/my-frames">
		<MyFramesScreen />
	</Route>,
	<Route exact path="/search-frames">
		<FrameIndexScreen />
	</Route>,
	<Route exact path="/my-lus">
		<MyLexicalUnitsScreen />
	</Route>,
	<Route exact path="/search-lus">
		<LuIndexScreen />
	</Route>,
	<Route exact path="/help">
		<ContactScreen />
	</Route>,
	<Route exact path="/register">
		<RegisterScreen />
	</Route>,
	<Route exact path="/search">
		<LuSearchScreen />
	</Route>,
	<Route path="/search/results">
		<LuSearchResultsScreens />
	</Route>,
	<Route exact path="/create/lu">
		<LuCreationScreen />
	</Route>,
	<Route exact path="/create/frame">
		<FrameCreationScreen />
	</Route>,
	<Route exact path="/create/frame-root">
		<FrameRootScreen />
	</Route>,
	<Route exact path="/frame/:id/definition">
		<FrameDefinitionScreen />
	</Route>,
	<Route exact path="/frame/:id/frame-relations">
		<FrameToFrameRelationScreen />
	</Route>,
	<Route exact path="/frame/:id/frame-elements">
		<FeScreen />
	</Route>,
	<Route exact path="/frame/:id/fe-descriptions">
		<FeDefinitionScreen />
	</Route>,
	<Route exact path="/frame/:id/fe-relations">
		<FeRelationScreen />
	</Route>,
	<Route exact path={["/frame/:id/summary", "/frame/:id/edit"]}>
		<FrameSummaryScreen />
	</Route>,
	<Route exact path="/frame/:id">
		<FrameReportScreen />
	</Route>,
	<Route exact path={["/lu/:id", "/lu/:id/edit"]}>
		<LuReportScreen />
	</Route>,
	<Route exact path="/about">
		<AboutScreen />
	</Route>,
	<Route exact path="/server-error">
		<ErrorScreen />
	</Route>,
	<Route exact path="/">
		<HomeScreen />
	</Route>,
];

const UNAUTHENTICATED_ROUTES = [
	<Route exact path="/">
		<WelcomeScreen />
	</Route>,
];

const GENERAL_ROUTES = [
	<Route>
		<NotFoundScreen />
	</Route>,
];

function App() {
	const { isLoading, isAuthenticated } = useAuth0();

	useEffect(() => setBodyBg(), []);

	return (
		// eslint-ignore-next-line
		<Router history={history}>
			<Flex justifyContent="center">
				<Flex width="size-4600">
					<Switch>
						{[
							isAuthenticated && AUTHENTICATED_ROUTES,
							!isLoading && !isAuthenticated && UNAUTHENTICATED_ROUTES,
							!isLoading && GENERAL_ROUTES,
						]}
					</Switch>
				</Flex>
			</Flex>
		</Router>
	);
}

export default App;
