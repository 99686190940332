import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import FrameDefinitionHelpDialog from './FrameDefinitionHelpDialog'

function FrameDefinitionFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>Frame definition</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <FrameDefinitionHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FrameDefinitionFieldLabel;