import React, { useState } from "react";
import useSWR from "swr";
import {
	ActionButton,
	AlertDialog,
	DialogContainer,
	Flex,
	Heading,
	Text,
	Well,
} from "@adobe/react-spectrum";
import { Link, useHistory, useParams } from "react-router-dom";

import Report from "@spectrum-icons/workflow/DesktopAndMobile";
import Share from "@spectrum-icons/workflow/ShareLight";

import Tags from "../components/Tags";
import FeViewer from "../components/FeViewer";
import PageHeading from "../components/PageHeading";
import FrameRelationViewer from "../components/FrameRelationViewer";

import AppBar from "../components/AppBar";
import { getUtcString } from "../utils/date";

import "@spectrum-web-components/accordion/sp-accordion.js";
import "@spectrum-web-components/accordion/sp-accordion-item.js";

function FrameReportScreen() {
	const history = useHistory();
	const { id: frameId } = useParams();

	const [isShareAlertOpen, setIsShareAlertOpen] = useState(false);
	const [hasShareError, setHasShareError] = useState(null);

	const { data: frame } = useSWR(`/frames/${frameId}?_embed=fes,lus`);
	const { data: relations } = useSWR(
		`/framerelations?idFrame=${frameId}&_expand=frames`
	);

	const reportLink = `http://webtool.framenetbr.ufjf.br/index.php/webtool/report/frame/showFrame/${frameId}`;

	function onPressLU(item) {
		history.push(`/lu/${item.key}`);
	}

	async function onPressShare() {
		if (navigator?.share) {
			await navigator.share({
				text: "Navigate to Lutma to see this frame.",
				url: window.location,
				title: "Frame",
			});
		} else {
			try {
				await navigator.clipboard.writeText(window.location);
				setHasShareError(false);
			} catch (err) {
				setHasShareError(true);
			}

			setIsShareAlertOpen(true);
		}
	}

	if (!frame) {
		return (
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<Heading level={3} margin="size-0">
					Loading...
				</Heading>
			</Flex>
		);
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<Link to={{ pathname: reportLink }} target="_blank">
					<ActionButton>
						<Report size="S" />
					</ActionButton>
				</Link>

				<>
					<ActionButton onPress={onPressShare}>
						<Share size="S" />
					</ActionButton>
					<DialogContainer onDismiss={() => setIsShareAlertOpen(false)}>
						{isShareAlertOpen && (
							<AlertDialog
								variant={hasShareError ? "error" : "information"}
								title={hasShareError ? "Error" : "Info"}
								primaryActionLabel="Close"
							>
								{hasShareError
									? "Failed to copy frame link to clipboard!"
									: "Succesfuly copied frame link to clipboard!"}
							</AlertDialog>
						)}
					</DialogContainer>
				</>
			</AppBar>

			<Flex
				flex={1}
				direction="column"
				gap="size-150"
				UNSAFE_className="sp-accordion"
			>
				<PageHeading
					subtitle="This is the report for the frame"
					marginTop="size-100"
					marginStart="size-200"
					marginEnd="size-200"
				>
					{frame.name}
				</PageHeading>
				<sp-accordion allow-multiple>
					<sp-accordion-item label="Definition" open>
						<Well>
							<Text>{frame.description}</Text>
						</Well>
					</sp-accordion-item>

					<sp-accordion-item label="Frame elements" open>
						<FeViewer fes={frame.fes} />
					</sp-accordion-item>

					<sp-accordion-item label="Relations">
						<FrameRelationViewer relations={relations} frame={frame} />
					</sp-accordion-item>

					<sp-accordion-item label="Lexical units">
						<Flex wrap>
							<Tags
								items={frame.lus.map((lu) => ({ key: lu.idLU, text: lu.name }))}
								onPressTag={onPressLU}
							/>
						</Flex>
					</sp-accordion-item>
				</sp-accordion>

				{frame.createdAt && (
					<Text margin="size-200">
						<span className="very-small-text">{`Created on ${getUtcString(
							frame.createdAt
						)}`}</span>
					</Text>
				)}
			</Flex>
		</Flex>
	);
}

export default FrameReportScreen;
