import FrameRelationTypes from "../constants/frameRelationTypes";

const LABEL_MAP = Object.assign(
	{},
	...FrameRelationTypes.map((x) => ({ [x.name]: x.labels }))
);

export function groupRelations(relations, frame) {
	const groups = {}
	
	for (let relation of relations) {
		let labels = getRelationLabels(relation, frame.idFrame)

		if (labels) {
			let relationLabel = labels.relation
			let other = labels.frame

			if (groups[relationLabel]) {
				groups[relationLabel].push(other)
			} else {
				groups[relationLabel] = [other]
			}
		}
	}

	return Object.entries(groups)
}


export function getRelationLabels(relation, frameId) {
	if (!LABEL_MAP[relation.type]) return null
	let label = LABEL_MAP[relation.type][relation.direction === 1 ? 1 : 0]

	let other
	if (relation.idFrame1 === frameId.toString()) {
		other = relation.frame2;
	} else {
		other = relation.frame1;
	}

	return {
		relation: label,
		name: other.name,
		frame: other,
	}
}