import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import IncorporatedFeHelpDialog from './IncorporatedFeHelpDialog';

function LuIncorporatedFeFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>Incorporated FE (optional)</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <IncorporatedFeHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default LuIncorporatedFeFieldLabel;