import React, { useState } from "react";
import useSWR from "swr";
import {
	ActionButton,
	Flex,
	SearchField,
	ProgressCircle,
} from "@adobe/react-spectrum";

import ActionHeader from "../components/ActionHeader";
import FrameIndex from "../components/FrameIndex";
import useQuery from "../hooks/useQuery";

import Close from "@spectrum-icons/workflow/Close";

function FrameIndexScreen() {
	const { q } = useQuery();
	const { data, error } = useSWR("/frames?attrs=name", {
		revalidateOnFocus: false,
		dedupingInterval: 15 * 60 * 1000,
	});

	const [filterText, setFilterText] = useState(q || "");
	const [filterValue, setFilterValue] = useState(q || "");
	const [filterTimeout, setFilterTimeout] = useState(null);

	function updateFilter(newValue) {
		clearTimeout(filterTimeout);
		setFilterText(newValue);
		setFilterTimeout(setTimeout(() => setFilterValue(newValue), 400));

		window.history.replaceState(null, document.title, `?q=${newValue}`);
	}

	return (
		<Flex flex={1} direction="column">
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<ActionHeader title="Frame Index" isCancelable={false}>
					<ActionButton onPress={() => window.close()} isQuiet>
						<Close size="S" />
					</ActionButton>
				</ActionHeader>
				<SearchField
					value={filterText}
					onChange={updateFilter}
					label="Searching Global FrameNet"
					width=""
				/>
				{!data && !error ? (
					<ProgressCircle
						aria-label="Loading..."
						isIndeterminate
						alignSelf="center"
						marginTop="size-150"
					/>
				) : (
					<FrameIndex data={data} query={filterValue} />
				)}
			</Flex>
		</Flex>
	);
}

export default FrameIndexScreen;
