import React from 'react';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function GenericHelpDialog({ title, text, close }) {
	return (
		<Dialog>
			<Heading>{title}</Heading>
			<Divider />
			<Content>
				<Text>{text}
				</Text>
			</Content>
			<ButtonGroup>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default GenericHelpDialog;