import React from 'react';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from '@adobe/react-spectrum';

function LemmaHelpDialog({ close } ) {
	return (
		<Dialog>
			<Heading>Incorporated FE</Heading>
			<Divider />
			<Content>
				<Text>LUs may incorporate one of the core FEs in a frame. For instance, the person.n LU evoking the <span className="frame-name">People</span> frame incorporates the FE People.</Text>
			</Content>
			<ButtonGroup>
				<Button variant="cta" onPress={close}>Got it!</Button>
			</ButtonGroup>
		</Dialog>
	)
}

export default LemmaHelpDialog;