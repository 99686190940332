import React from "react";
import useSWR from "swr";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import {
	ActionButton,
	Flex,
	ProgressCircle,
	Text,
} from "@adobe/react-spectrum";

import Add from "@spectrum-icons/workflow/Add";

import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";
import FramePreview from "../components/FramePreview";

const MyFramesScreen = observer(() => {
	const history = useHistory();

	const { data, error } = useSWR("/user/frames?_embed=fes,lus,relcount");
	const frames = data ? data.filter((frame) => frame.name) : [];

	const isLoading = !data && !error;

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<ActionButton onPress={() => history.push("create/frame")}>
					<Add size="S" />
				</ActionButton>
			</AppBar>
			<Flex flex={1} direction="column" gap="size-100" margin="size-200">
				<PageHeading align="center">My Frames</PageHeading>
				<Flex direction="column">
					{isLoading ? (
						<ProgressCircle
							label="Loading..."
							isIndeterminate
							alignSelf="center"
							marginTop="size-150"
						/>
					) : frames.length === 0 ? (
						<Text marginTop="size-150">No frames to be shown.</Text>
					) : (
						frames.map((frame, index) => (
							<FramePreview
								key={frame.idFrame}
								frame={frame}
								isLast={index === frames.length - 1}
							/>
						))
					)}
				</Flex>
			</Flex>
		</Flex>
	);
});

export default MyFramesScreen;
