import React, { useContext } from 'react';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from '@adobe/react-spectrum';
import { useHistory, useLocation } from "react-router-dom";

import ApiContext from "../contexts/ApiContext";


function DeleteConfirmationDialog({ close } ) {
	const history = useHistory();
	const location = useLocation();

	const api = useContext(ApiContext);

	const paths = location.pathname.split("/");
	const id = paths[paths.length - 2]
	const type = paths[paths.length - 3] // frame or lu 

	async function onDelete() {
		await api.delete(`${type}s/${id}`);
		history.push('/my-frames');
	}

	return (
		<Dialog>
			<Heading>Confirm action</Heading>
			<Divider />
			<Content>
				<Text>If you delete this {type === 'lu' ? 'lexical unit' : 'frame'}, all of its information will be lost.</Text>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={close}>Go back</Button>
				<Button variant="negative" onPress={onDelete}>Delete</Button>
			</ButtonGroup>
		</Dialog>
	)
}

export default DeleteConfirmationDialog;