import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FeDefinitionHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>How to create frame element definitions?</Heading>
			<Divider />
			<Content>
				<Text>
					<p>
						A brief definition of the semantic role considering its behavior in
						the frame. The definition specifies how the FE name must be
						interpreted in the frame.
					</p>
					<p>
						e.g: <span className="frame-name">Change_position_on_a_scale</span>{" "}
						frame
						<br />
						Item: The entity that has a position on the scale.
					</p>
					<p>
						<span className="frame-name">Being_detached</span> frame
						<br />
						Item: Identifies the entity that is detached from a Source.
					</p>
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() =>
						window.open("https://www.youtube.com/watch?v=hhAgJPt8oIU", "_blank")
					}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FeDefinitionHelpDialog;
