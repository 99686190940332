import React, { useRef } from "react";
import { TextField } from "@adobe/react-spectrum";

let TIMER;

function TextFieldWithFixedPattern({
	setPatternFunc,
	pattern,
	value,
	onChange,
	...fieldProps
}) {
	const fieldRef = useRef();

	function setCaretPosition() {
		if (fieldRef.current && value) {
			const nameFieldEl = fieldRef.current.getInputElement();
			const start = nameFieldEl.selectionStart;
			const end = nameFieldEl.selectionEnd;

			if (start === end) {
				const match = value.match(pattern);

				if (
					match &&
					start >= match.index &&
					start <= match.index + match[0].length
				) {
					let caretPosition = match.index;

					clearTimeout(TIMER);
					TIMER = setTimeout(
						() => nameFieldEl.setSelectionRange(caretPosition, caretPosition),
						0
					);
				}
			}
		}
	}

	return (
		<TextField
			{...fieldProps}
			ref={fieldRef}
			value={value}
			onFocus={setCaretPosition}
			onKeyUp={setCaretPosition}
			onChange={(newValue) => {
				if (!value || newValue.length <= 1) {
					onChange(setPatternFunc(newValue));
					setCaretPosition();
				} else if (newValue.match(pattern)) {
					onChange(setPatternFunc(newValue.replace(pattern, "")));
				} else {
					setCaretPosition();
				}
			}}
		/>
	);
}

export default TextFieldWithFixedPattern;
