import useSWR from "swr";

import POSTags from "../constants/posTags";

export default function usePosTypes() {
	const { data: posData, error } = useSWR("/pos");

	return {
		error,
		data: (posData || []).map((pos) => ({
			idPOS: pos.POS,
			...POSTags.find((p) => p.tag === pos.POS),
		})),
	};
}
