import useSWR from "swr"

export function useFrameNameValidation(name, root, languages = [], isScenario = false) {
	const errors = []
	const warnings = []
	const errorData = {}
	const cleanName = name ? name.replace(/_scenario$/, '') : ''

	if (cleanName) {
		const firstChar = cleanName[0]
		const remainder = cleanName.slice(1)
		const tokens = cleanName.split('_')
		const endsWithIng = tokens.some((t) => /ing$/.test(t))
		
		if (firstChar === firstChar.toLowerCase() || remainder !== remainder.toLowerCase()) {
			errors.push('Names must be capitalized.')
		}
		if (/\s/.test(cleanName)) {
			errors.push('Names must not contain whitespaces. Use underscore instead.')
		}
		if (!/^[\sa-zA-Z_]+$/.test(cleanName)) {
			errors.push('Names must be in English and not include numbers or diacritics.')
		}
		if (root === 'event' && !(endsWithIng || /^Becoming_\w+/.test(cleanName)) ) {
			warnings.push('Eventive frames usually have names ending in -ing or have the form Becoming_x.')
		}
		if (root === 'state' && !(/\w+_state$/.test(cleanName) || /^Being_\w+/.test(cleanName)) ) {
			warnings.push('State frames usually have names with forms Being_x or x_state.')
		}
	}

	const { data, error } = useSWR((name && errors.length === 0) ? `/frames?name=${name}&_embed=languages` : null)
	const existingFrame = data && data.length > 0 && data.find(frame => frame.name === name)

	if (error) { 
		errors.push(error)
	} else if (existingFrame) {
		const languageSet = new Set((languages || [] ).map(l => l.idLanguage))
		const hasLanguageInCommon = existingFrame.languages.filter(l => languageSet.has(l.idLanguage)).length > 0

		if (!(isScenario && existingFrame.isScenario && !hasLanguageInCommon)) {
			errors.push('Frame already exists in database.')
		}

		errorData.existingFrame = existingFrame
	}

	const state = errors.length > 0 ? 'invalid' : (data && cleanName ? 'valid' : null)

	return [state, errors, warnings, errorData]
}

export default useFrameNameValidation