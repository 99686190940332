import React from "react";
import { v4 } from "uuid";

import "@spectrum-web-components/tags/sp-tags.js";
import "@spectrum-web-components/tags/sp-tag.js";

export default function Tags({ items, onPressTag }) {
	const tagData = items.map((item) =>
		typeof item !== "string" ? item : { key: v4(), text: item }
	);

	function onClick(item) {
		if (onPressTag) {
			onPressTag(item);
		}
	}

	return (
		<div className="sp-tags">
			<sp-tags>
				{tagData.map((item) => (
					<sp-tag key={item.key} onClick={() => onClick(item)}>
						{item.text}
					</sp-tag>
				))}
			</sp-tags>
		</div>
	);
}
