import React from "react";
import { Button, Flex, Heading, Text, Well } from "@adobe/react-spectrum";
import { useHistory } from "react-router-dom";

import AppBar from "../components/AppBar";

function FrameCreationScreen() {
	const history = useHistory();

	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<Heading level={3} margin="size-0">
					Create a New Frame
				</Heading>
				<Well>
					<Text>
						The frame I want to create can be evoked by some lexical material.
					</Text>
					<Flex flex={1} justifyContent="end" marginTop="size-150">
						<Button variant="cta" onPress={() => history.push("/search")}>
							Create lexical frame
						</Button>
					</Flex>
				</Well>
				<Well>
					<Text>
						The frame I want to create cannot be evoked by any lexical material.
					</Text>
					<Flex flex={1} justifyContent="end" marginTop="size-150">
						<Button
							variant="cta"
							onPress={() => history.push("/create/frame-root?type=nonlexical")}
						>
							Create non-lexical frame
						</Button>
					</Flex>
				</Well>
			</Flex>
		</Flex>
	);
}

export default FrameCreationScreen;
