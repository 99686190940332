import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Add from "@spectrum-icons/workflow/Add";
import { useHistory, useParams } from "react-router-dom";
import {
	ActionButton,
	Button,
	ButtonGroup,
	Checkbox,
	CheckboxGroup,
	DialogTrigger,
	Flex,
	Text,
} from "@adobe/react-spectrum";

import Cancel from "@spectrum-icons/workflow/Cancel";

import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";
import CreateFeRelationDialog from "../dialogs/CreateFeRelationDialog";
import ApiContext from "../contexts/ApiContext";
import useFeRelations from "../hooks/useFeRelations";

import CancelConfirmationDialog from "../dialogs/CancelConfirmationDialog";

function FeRelationScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();
	const { id: frameId } = useParams();

	const { data: frame } = useSWR(`/frames/${frameId}?_embed=fes`);
	const { data: dbFeRelations } = useFeRelations(frameId);

	const [relations, setRelations] = useState([]);

	useEffect(() => {
		const isFirstUpdate = dbFeRelations?.length > 0 && relations.length === 0;
		const hasRelationsChanged =
			relations.length > 0 && dbFeRelations.length !== relations.length;

		if (isFirstUpdate || hasRelationsChanged) {
			const relArray = dbFeRelations.slice();

			if (hasRelationsChanged) {
				relArray.forEach((relation) => {
					const current = relations.find(
						(currRel) => relation.idEntityRelation === currRel.idEntityRelation
					);

					if (current) {
						relation.isSelected = current.isSelected;
					}
				});
			}

			setRelations(relArray);
		}
	}, [dbFeRelations, relations]);

	const selected = relations
		.filter((relation) => relation.isActive)
		.map((relation) => relation.idEntityRelation);

	function onChange(selected) {
		const selectedSet = new Set(selected);

		setRelations(
			relations.map((relation) => ({
				...relation,
				isActive: selectedSet.has(relation.idEntityRelation),
			})),
			false
		);
	}

	function onPressSkip() {
		history.push(`/frame/${frameId}/summary`);
	}

	async function onPressSave() {
		// TODO: save only the ones that were modified
		await Promise.all(
			relations.map((relation) =>
				api.patch(`ferelations/${relation.idRelationType}`, {
					isActive: relation.isActive,
				})
			)
		);
		history.push(`/frame/${frameId}/summary`);
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<DialogTrigger>
					<ActionButton>
						<Cancel size="S" />
					</ActionButton>
					{(close) => <CancelConfirmationDialog close={close} />}
				</DialogTrigger>
			</AppBar>

			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<PageHeading
					title="FE-to-FE relations"
					subtitle={frame ? `${frame.name} has the following` : "Loading..."}
				/>

				<CheckboxGroup
					value={selected}
					onChange={onChange}
					aria-label="Frame element relations"
				>
					{relations
						.filter((relation) => !relation.isInvalid)
						.map((relation) => (
							<Checkbox
								key={relation.idEntityRelation}
								value={relation.idEntityRelation}
							>
								<span className="paragraph-font-size">
									There is a FE-to-FE relation of{" "}
									<span className="bold-text">{relation.type}</span> between{" "}
									<span className="bold-text">{relation.fe1Name}</span> and{" "}
									<span className="bold-text">{relation.fe2Name}</span>
								</span>
							</Checkbox>
						))}
				</CheckboxGroup>

				<DialogTrigger>
					<ActionButton
						variant="secondary"
						alignSelf="start"
						isDisabled={!frame}
					>
						<Add />
						<Text>Add new FE-to-FE relation</Text>
					</ActionButton>
					{(close) => (
						<CreateFeRelationDialog
							close={close}
							frame={frame}
							relations={relations}
						/>
					)}
				</DialogTrigger>

				<ButtonGroup align="center" marginTop="size-300">
					<Button
						variant="cta"
						onPress={onPressSave}
						isDisabled={relations.length === 0}
					>
						SAVE FE-TO-FE RELATIONS
					</Button>
					<Button variant="secondary" onPress={onPressSkip}>
						SKIP THIS STEP
					</Button>
				</ButtonGroup>
			</Flex>
		</Flex>
	);
}

export default FeRelationScreen;
