import React from "react";
import useSWR from "swr";
import { observer } from "mobx-react-lite";
import { Flex, ProgressCircle, Text } from "@adobe/react-spectrum";

import PageHeading from "../components/PageHeading";
import LuPreview from "../components/LuPreview";

import AppBar from "../components/AppBar";

const MyLexicalUnitsScreen = observer(() => {
	const { data, error } = useSWR("user/lus?_embed=fe&_expand=frame");

	const lus = data ? data.filter((lu) => lu.frame.name) : [];
	const isLoading = !data && !error;

	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" gap="size-100" margin="size-200">
				<PageHeading align="center">My Lexical Units</PageHeading>
				<Flex direction="column">
					{isLoading ? (
						<ProgressCircle
							label="Loading..."
							isIndeterminate
							alignSelf="center"
							marginTop="size-150"
						/>
					) : lus.length === 0 ? (
						<Text marginTop="size-150">No lexical units to be shown.</Text>
					) : (
						lus.map((lu, index) => (
							<LuPreview
								key={lu.idLU}
								lu={lu}
								isLast={index === lus.length - 1}
							/>
						))
					)}
				</Flex>
			</Flex>
		</Flex>
	);
});

export default MyLexicalUnitsScreen;
