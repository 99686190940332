export default [
	{
		"tag": "N",
		"name": "Noun",
	},
	{
		"tag": "A",
		"name": "Adjective",
	},
	{
		"tag": "NUM",
		"name": "Number",
	},
	{
		"tag": "V",
		"name": "Verb",
	},
	{
		"tag": "ART",
		"name": "Article",
	},
	{
		"tag": "PRON",
		"name": "Pronoun",
	},
	{
		"tag": "ADV",
		"name": "Adverb",
	},
	{
		"tag": "PREP",
		"name": "Preposition",
	},
	{
		"tag": "C",
		"name": "Conjunction",
	},
	{
		"tag": "INTJ",
		"name": "Interjection",
	},
	{
		"tag": "SCON",
		"name": "Subortinating Conjunction",
	},
	{
		"tag": "CCON",
		"name": "Coordinating Conjunction",
	},
	{
		"tag": "AVP",
		"name": "Adverbial Preposition",
	},
	{
		"tag": "IDIO",
		"name": "Idiomatic",
	}
]