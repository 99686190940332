import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { mutate } from "swr";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Flex,
	Heading,
	Item,
	Link as SpectrumLink,
	Picker,
	Text,
} from "@adobe/react-spectrum";

import FeMapper from "../components/FeMapper";
import ActionHeader from "../components/ActionHeader";
import RemoteComboBox from "../components/RemoteComboBox";
import FieldLabelWithHelp from "../components/FieldLabelWithHelp";
import ApiContext from "../contexts/ApiContext";

import useFrameRelationTypes from "../hooks/useFrameRelationTypes";

function CreateFrameToFrameRelationDialog({ frame, close }) {
	const api = useContext(ApiContext);

	const history = useHistory();

	const { data: relationTypes } = useFrameRelationTypes();

	const [relationType, setRelationType] = useState("");
	const [isSaveDisabled, setIsSaveDisabled] = useState(false);
	const [other, setOther] = useState();
	const [feMapping, setFeMapping] = useState({});

	const selectedRelation = relationTypes.find(
		(relation) => relation.idRelationType === Number(relationType)
	);
	const isInheritance =
		selectedRelation && selectedRelation.name === "rel_inheritance";

	function onFrameSelectionChange(key, other) {
		setOther(other);
	}

	async function onSubmit() {
		setIsSaveDisabled(true);

		// 1. Save new FEs
		const newFeNames = Object.values(feMapping)
			.filter((fe) => fe?.new)
			.map((fe) => fe.name);
		const uniqueFeNames = [...new Set(newFeNames)];

		const result = await Promise.all(
			uniqueFeNames.map((name) =>
				api.post(`frames/${frame.idFrame}/fes`, {
					idFrame: frame.idFrame,
					name: name,
					isDraft: true,
					isSelected: true,
				})
			)
		);

		// 2. Update mapping with newly created objects
		const updatedMapping = {};

		const feByName = Object.fromEntries(
			result.map((res) => [res.data.name, res.data])
		);

		for (let key in feMapping) {
			if (feMapping[key]) {
				const definition = feMapping[key];
				const isNew = definition.new;
				updatedMapping[key] = isNew ? feByName[definition.name] : definition;
			}
		}

		// 3. Save the frame relation with FE mapping
		await api.post(`framerelations`, {
			idFrame1: other.idFrame,
			idFrame2: frame.idFrame,
			idRelationType: parseInt(relationType),
			feLinks: Object.keys(updatedMapping).map((key) => ({
				idFrameElement1: Number(key),
				idFrameElement2: updatedMapping[key].idFrameElement,
			})),
		});

		setIsSaveDisabled(false);

		mutate(`/frames/${frame.idFrame}?_embed=fes`);
		mutate(`/framerelations?idFrame=${frame.idFrame}&_expand=frames`);

		close();

		if (newFeNames.length > 0) {
			history.push(`/frame/${frame.idFrame}/fe-descriptions`);
		} else {
			history.push(`/frame/${frame.idFrame}/frame-elements`);
		}
	}

	return (
		<Dialog mobileType="fullscreen">
			<Heading>
				<ActionHeader
					title={frame.name}
					description="Adding a new Frame-to-Frame relation to"
					isCancelable={false}
				/>
			</Heading>
			<Divider />
			<Content>
				<Flex direction="column" gap="size-150">
					<Picker
						label={frame.name}
						items={relationTypes}
						selectedKey={relationType}
						onSelectionChange={setRelationType}
						width=""
					>
						{(item) => <Item key={item.idRelationType}>{item.labels[0]}</Item>}
					</Picker>

					<RemoteComboBox
						label={
							selectedRelation ? (
								<FieldLabelWithHelp
									label="Frame"
									dialogTitle={selectedRelation.labels[0]}
									dialogText={selectedRelation.description}
								/>
							) : (
								"Frame"
							)
						}
						urlFunc={(v) => `frames?name=${v}&_embed=fes`}
						onSelectionChange={onFrameSelectionChange}
						getKey={(item) => String(item.idFrame)}
						isDisabled={!relationType}
						placeholder="Search in frames"
						width=""
					>
						{(item) => (
							<Item key={item.idFrame} textValue={item.name}>
								<Text>{item.name}</Text>
								<Text slot="description">{`${item.description.slice(
									0,
									70
								)}...`}</Text>
							</Item>
						)}
					</RemoteComboBox>
					{other && (
						<>
							<SpectrumLink>
								<Link to={`/frame/${other.idFrame}`} target="_blank">
									See full frame description
								</Link>
							</SpectrumLink>
							<FeMapper
								frameA={other}
								frameB={frame}
								onChange={setFeMapping}
								isRequired={isInheritance}
							/>
						</>
					)}
				</Flex>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={close}>
					Close
				</Button>
				<Button
					variant="cta"
					isDisabled={
						isSaveDisabled ||
						!relationType ||
						!other ||
						(isInheritance && Object.values(feMapping).some((fe) => !fe?.name))
					}
					onPress={onSubmit}
				>
					Save
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default CreateFrameToFrameRelationDialog;
