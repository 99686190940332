import React from "react";
import Help from "@spectrum-icons/ui/HelpSmall";
import { ActionButton, DialogTrigger, Heading } from "@adobe/react-spectrum";

import FieldLabelText from "../../FieldLabelText";
import FeNonCoreHelpDialog from "./FeNonCoreHelpDialog";

function FeNonCoreHeading() {
	return (
		<DialogTrigger>
			<ActionButton alignSelf="start" isQuiet>
				<Heading level={5}>
					<FieldLabelText>SELECT NON-CORE FRAME ELEMENTS</FieldLabelText>
				</Heading>
				<Help />
			</ActionButton>
			{(close) => <FeNonCoreHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FeNonCoreHeading;
