import React from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { ActionButton, Flex, Text } from "@adobe/react-spectrum";

import LinkOut from "@spectrum-icons/workflow/LinkOut";

import { groupRelations } from "../utils/frameRelations";

export default function FrameRelationViewer({ relations, frame }) {
	const history = useHistory();

	if (!relations || !frame) {
		return null;
	}

	return (
		<Flex direction="column" gap="size-75">
			{relations &&
				groupRelations(relations, frame).map((group) => (
					<Flex key={v4()} wrap alignItems="center" gap="size-75">
						<Text marginEnd="size-50">{group[0]}:</Text>
						{group[1].map((other) => (
							<ActionButton
								key={other.idFrame}
								onPress={() => history.push(`/frame/${other.idFrame}`)}
							>
								<LinkOut size="S" />
								<Text>{other.name}</Text>
							</ActionButton>
						))}
					</Flex>
				))}
		</Flex>
	);
}
