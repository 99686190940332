import useSWR from 'swr'

export function useLuSearch(type, query) {
	const { q, lang, pos } = query;
	let url;

	if (type === 'match') {
		url = `/lus?q=${q}&pos=${pos}&idLanguage=${lang}&_expand=frame`
	} else if (type === 'global') {
		url = `/lus?q=${q}&pos=${pos}&_expand=frame`
	} else {
		url = `/synonyms/lus?q=${q}&idLanguage=${lang}&_expand=frame`
	}

	const { data, error } = useSWR(url);
	
	const filtered = Array.isArray(data)
		? data.filter(lu => !Boolean(lu.isDraft))
		: data;

	return { data: filtered, error };
}

export default useLuSearch;