import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Add from "@spectrum-icons/workflow/Add";
import { useHistory, useParams } from "react-router-dom";
import {
	ActionButton,
	Button,
	ButtonGroup,
	Checkbox,
	CheckboxGroup,
	DialogTrigger,
	Flex,
	Text,
} from "@adobe/react-spectrum";

import Alert from "@spectrum-icons/workflow/Alert";
import Cancel from "@spectrum-icons/workflow/Cancel";

import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";
import FeCoreHeading from "../components/tutorials/fe/FeCoreHeading";
import FeNonCoreHeading from "../components/tutorials/fe/FeNonCoreHeading";
import CreateFeDialog from "../dialogs/CreateFeDialog";
import ApiContext from "../contexts/ApiContext";

import CancelConfirmationDialog from "../dialogs/CancelConfirmationDialog";

import FRAME_TEMPLATES from "../constants/frameTemplates";

function FeScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();
	const { id: frameId } = useParams();

	const { data: frame } = useSWR(`/frames/${frameId}?_embed=fes`);
	const { data: relations } = useSWR(`/framerelations?idFrame=${frameId}`);

	const [fes, setFes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const isFirstUpdate = frame?.fes.length > 0 && fes.length === 0;
		const hasFrameChanged =
			fes.length > 0 &&
			frame?.fes.length !== fes.filter((fe) => fe.idFrameElement).length;

		if (frame && (isFirstUpdate || hasFrameChanged)) {
			const feArray = frame.fes.map((fe) => ({
				...fe,
				// makes it easier to see if it changed
				origIsSelected: fe.isSelected,
			}));

			const frameTemplate = FRAME_TEMPLATES.find(
				(template) => template.name.toLowerCase() === frame.root
			);

			if (frameTemplate) {
				const feNameSet = new Set(frame.fes.map((fe) => fe.name));

				feArray.push(
					...frameTemplate.fes.filter((fe) => !feNameSet.has(fe.name))
				);
			}

			if (hasFrameChanged) {
				// keep selection before updating state
				feArray.forEach((fe) => {
					const current = fes.find(
						(currFe) => fe.idFrameElement === currFe.idFrameElement
					);
					if (current) {
						fe.isSelected = current.isSelected;
					}
				});
			}

			setFes(feArray);
		}
	}, [frame, fes]);

	const coreFes = [];
	const nonCoreFes = [];
	const selected = fes.filter((fe) => fe.isSelected);

	for (let fe of fes) {
		if (fe.type === "core" || fe.type === "unexpressed") {
			coreFes.push(fe);
		} else {
			nonCoreFes.push(fe);
		}
	}

	const disabled = new Set(
		(relations || []).flatMap((relation) =>
			relation.type === "rel_inheritance" &&
			Boolean(Number(relation.isActive)) &&
			relation.idFrame2 === frameId
				? relation.feLinks.map((link) => Number(link.idFrameElement2))
				: []
		)
	);

	async function onChangeCheckBoxGroup(selected) {
		const selectedSet = new Set(selected);

		setFes(
			fes.map((fe) => ({
				...fe,
				isSelected: selectedSet.has(fe.name),
			}))
		);
	}

	async function save() {
		const newFes = fes.filter((fe) => !fe.idFrameElement && fe.isSelected);
		const updatedFes = fes.filter(
			(fe) => fe.idFrameElement && fe.origIsSelected !== fe.isSelected
		);

		setIsLoading(true);
		await Promise.all(
			updatedFes.map((fe) =>
				api.patch(`/frames/${frameId}/fes/${fe.idFrameElement}`, {
					// prevents an error
					isSelected: Number(fe.isSelected),
				})
			)
		);
		await Promise.all(
			newFes.map((fe) =>
				api.post(`/frames/${frameId}/fes`, {
					idFrame: frameId,
					type: "peripheral",
					...fe,
				})
			)
		);
		setIsLoading(false);

		if (fes.length > 1) {
			history.push(`/frame/${frameId}/fe-relations`);
		} else {
			history.push(`/frame/${frameId}/summary`);
		}
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<DialogTrigger>
					<ActionButton>
						<Cancel size="S" />
					</ActionButton>
					{(close) => <CancelConfirmationDialog close={close} />}
				</DialogTrigger>
			</AppBar>
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<PageHeading
					title="Frame elements"
					description={frame ? `${frame.name} has the following` : "Loading..."}
				/>

				<DialogTrigger>
					<ActionButton
						variant="secondary"
						alignSelf="start"
						isDisabled={!frame}
					>
						<Add />
						<Text>Create New FE</Text>
					</ActionButton>
					{(close) => (
						<CreateFeDialog close={close} frame={frame} fes={frame?.fes} />
					)}
				</DialogTrigger>

				<CheckboxGroup
					value={selected.map((fe) => fe.name)}
					onChange={onChangeCheckBoxGroup}
					aria-label="Frame elements"
				>
					<FeCoreHeading />

					{coreFes.map((fe) => (
						<Checkbox
							key={fe.id || fe.name}
							value={fe.name}
							isDisabled={disabled.has(fe.idFrameElement)}
						>
							<span className="paragraph-font-size bold-text">{fe.name}</span>
							<span className="paragraph-font-size">{`: ${fe.description}`}</span>
						</Checkbox>
					))}

					<FeNonCoreHeading />

					{nonCoreFes.map((fe) => (
						<Checkbox
							key={fe.id || fe.name}
							value={fe.name}
							isDisabled={disabled.has(fe.idFrameElement)}
						>
							<span className="paragraph-font-size bold-text">{fe.name}</span>
							<span className="paragraph-font-size">{`: ${fe.description}`}</span>
						</Checkbox>
					))}
				</CheckboxGroup>

				{disabled.size > 0 && (
					<span className="warning-text">
						<Alert aria-label="Warning" color="notice" size="XS" />
						<span>
							Some FEs cannot be unselected because they were inherited from a
							frame relation. To change that, disable the frame relation.
						</span>
					</span>
				)}

				<ButtonGroup align="center" marginTop="size-300">
					<Button
						variant="cta"
						onPress={save}
						isDisabled={
							isLoading ||
							!frame ||
							!selected.some(
								(fe) => fe.type === "core" || fe.type === "unexpressed"
							)
						}
					>
						SAVE FRAME ELEMENTS
					</Button>
				</ButtonGroup>
			</Flex>
		</Flex>
	);
}

export default FeScreen;
