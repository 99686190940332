export default [
	{
		name: 'Event',
		definition: 'An Event involves at least one participant and takes place at a Place and Time.',
		fes: [
			{
				name: 'Circumstances',
				description: 'Some specification of the state of the world (at a particular time and place) which is specifically independent of the Event itself and any of its participants.',
			},
			{
				name: 'Degree',
				description: 'Some gradable attribute that modifies the expected value for it.',
			},
			{
				name: 'Depictive',
				description: 'Describes the Entity at the time of the Event.',
			},
			{
				name: 'Duration',
				description: 'The amount of time for which the Event is ongoing.',
			},
			{
				name: 'Explanation',
				description: 'A proposition from which, in many cases, the motivations or causes of the emergence of the Event are shown.',
			},
			{
				name: 'Frequency',
				description: 'The number of times the Event occurs per some unit of time.',
			},
			{
				name: 'Manner',
				description: 'Any description of the Event which is not covered by more specific FEs.',
			},
			{
				name: 'Means',
				description: 'An action through which the Entity or Cause accomplishes the Event indicated by the target.',
			},
			{
				name: 'Place',
				description: 'The location where the Event takes place.',
			},
			{
				name: 'Purpose',
				description: 'An action that the Entity intends to accomplish by performing the Event.',
			},
			{
				name: 'Result',
				description: 'The ultimate effect of the Event.',
			},
			{
				name: 'Time',
				description: 'The time when the Event occurs.',
			},
		]
	},
	{
		name: 'Entity',
		definition: 'An Entity can be a thing, a living being, a concept.',
		fes: [
			{
				name: 'Constituent_parts',
				description: 'Salient parts or substances that make up the Entity.',
			},
			{
				name: 'Container_possessor',
				description: 'Some location containing the Entity.',
			},
			{
				name: 'Creator',
				description: 'The individual or individuals that bring the Entity into existence.',
			},
			{
				name: 'Descriptor',
				description: 'The characterization of a temporary condition of the Entity.',
			},
			{
				name: 'Formational_cause',
				description: 'The indication of the causer or action which makes the Entity what it is.',
			},
			{
				name: 'Inherent_purpose',
				description: 'The activity in which the Entity is supposed to take part.',
			},
			{
				name: 'Material',
				description: 'Any indication of what makes up the Entity.',
			},
			{
				name: 'Name',
				description: 'The term used to specify the Entity.',
			},
			{
				name: 'Relative_location',
				description: 'The place relative to which the Entity is located.',
			},
			{
				name: 'Time_of_creation',
				description: 'The time when the Entity comes into existence.',
			},
			{
				name: 'Type',
				description: 'The indication of the type of the Entity.',
			},
			{
				name: 'Use',
				description: 'The use for which the Entity is intended.',
			},
		],
	},
	{
		name: 'Relation',
		definition: 'A relation holds between two or more Entities, Events or States.',
		fes: [
			{
				name: 'Accessibility',
				description: 'An indication of how accessible the profiled Entity, Event or State is to some participant in the speech context. It normally describes availability for manipulation and use, but may simply describe how easily seen or difficult to see it is.',
			},
			{
				name: 'Degree',
				description: 'A modifier expressing the extent to which the Relation occurs.',
			},
			{
				name: 'Deixis',
				description: 'An indication of how the locative phrase relates to the grounding of the speech situation.',
			},
			{
				name: 'Direction',
				description: 'The Direction from a reference location (generally, the deictic center) of a path to the profiled Entity, Event or State.',
			},
			{
				name: 'Directness',
				description: 'An indication of how closely the position of the profiled Entity, Event or State matches the prototype expected from the schema associated with the target.',
			},
			{
				name: 'Temporal_profile',
				description: 'A description of how the access path to the non-profiled Entity, Event or State interacts with time.',
			},
			{
				name: 'Time',
				description: 'The time when the Relation takes place.',
			},
		]
	},
	{
		name: 'Attribute',
		definition: 'An Attribute with some Value is applied to an Entity.',
		fes: [
			{
				name: 'Circumstances',
				description: 'The state of the world (at a particular time and place) which is specifically independent of the Attribute itself.',
			},
			{
				name: 'Degree',
				description: 'A modifier expressing degree.',
			},
			{
				name: 'Explanation',
				description: 'A proposition from which the causes for the emergence of an Attribute are shown.',
			},
			{
				name: 'Manner',
				description: 'Any description of how the Attribute takes place which is not covered by more specific FEs.',
			},
			{
				name: 'Place',
				description: 'The location where the Entity has the Attribute.',
			},
			{
				name: 'Time',
				description: 'The time when the Entity is in the state of having a particular Attribute.',
			},
		],
	},
	{
		name: 'State',
		definition: 'A State represents a stable situation.',
		fes: [
			{
				name: 'Circumstances',
				description: 'The state of the world (at a particular time and place) which is specifically independent of the State itself and of any of its participants.',
			},
			{
				name: 'Degree',
				description: 'The description of the degree to which the State holds.',
			},
			{
				name: 'Duration',
				description: 'The length of time from the beginning of a State to its end.',
			},
			{
				name: 'Explanation',
				description: 'Any object or event that brings about the State.',
			},
			{
				name: 'Manner',
				description: 'Any description of the State which is not covered by more specific FEs.',
			},
			{
				name: 'Place',
				description: 'The location where the State holds.',
			},
			{
				name: 'Time',
				description: 'The time when the Entity is in the State.',
			},
		],
	},
	{
		name: 'Undefined',
		definition: 'None of the above.',
		fes: [],
	}
]