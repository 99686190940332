import React, { useContext, useEffect } from "react";
import useSWR from "swr";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { Flex, Heading, ProgressCircle } from "@adobe/react-spectrum";

import UiStateContext from "../contexts/UiStateContext";
import ErrorScreen from "../screens/ErrorScreen";

const HomeScreen = observer(() => {
	const uiState = useContext(UiStateContext);

	const { data: user, error } = useSWR("/user");

	useEffect(() => {
		if (user) {
			uiState.setUserData(user);
		};
	}, [uiState, user]);

	function isRegistered(user) {
		return (
			user.hasAgreedWithTerms &&
			user.affiliation &&
			user.highestDegree &&
			user.degreeGrantedBy &&
			user.languages.length > 0
		);
	}

	if (error) {
		return <ErrorScreen />;
	} else if (user) {
		return <Redirect to={isRegistered(user) ? "/my-frames" : "/register"} />
	} else {
		return (
			<Flex
				flex={1}
				direction="column"
				alignItems="center"
				justifyContent="center"
				gap="size-150"
				margin="size-200"
				height="90vh"
			>
				<ProgressCircle aria-label="Loading..." size="L" isIndeterminate />
				<Heading>Loading Lutma...</Heading>
			</Flex>
		);
	}
});

export default HomeScreen;
