import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import {
	ButtonGroup,
	Button,
	Checkbox,
	DialogTrigger,
	Flex,
	TextArea,
	TextField,
} from "@adobe/react-spectrum";
import { useHistory, useParams } from "react-router-dom";

import Alert from "@spectrum-icons/workflow/Alert";

import useFrameNameValidation from "../hooks/validation/useFrameNameValidation";
import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";
import FrameNameFieldLabel from "../components/tutorials/frame/FrameNameFieldLabel";
import FrameDefinitionFieldLabel from "../components/tutorials/frame/FrameDefinitionFieldLabel";
import FrameScenarioFieldLabel from "../components/tutorials/frame/FrameScenarioFieldLabel";
import TextFieldWithFixedPattern from "../components/TextFieldWithFixedPattern";
import AddLanguageConfirmationDialog from "../dialogs/AddLanguageConfirmationDialog";
import ApiContext from "../contexts/ApiContext";

function FrameDefinitionScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();
	const { id: frameId } = useParams();

	const { data: frame, mutate } = useSWR(`/frames/${frameId}?`);
	const { data: languages } = useSWR(`/frames/${frameId}/languages`);

	const [name, setName] = useState();
	const [definition, setDefinition] = useState();
	const [isScenario, setIsScenario] = useState(false);
	const [isConfirmationOpen, setConfirmationOpen] = useState(false);

	const root = frame ? frame.root : "";

	const [nameValidationState, validationErrors, validationWarnings, errorData] =
		useFrameNameValidation(name, root, languages, isScenario);

	useEffect(() => {
		if (frame) {
			if (!name) setName(frame.name);
			if (!definition) setDefinition(frame.description);
		}
	}, [frame, name, definition]);

	async function onPressSave() {
		if (errorData.existingFrame) {
			setConfirmationOpen(true);
			return;
		}

		mutate(async (frame) => {
			const { data } = await api.patch(`frames/${frame.idFrame}`, {
				name: name,
				description: definition,
				isScenario: isScenario,
			});

			if (isScenario) {
				history.push(`/frame/${frame.idFrame}/frame-elements`);
			} else {
				history.push(`/frame/${frame.idFrame}/frame-relations`);
			}

			return data;
		});
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<PageHeading
					title={frame ? frame.name || name : "Loading..."}
					subtitle={`You are creating a new ${root} frame called`}
				/>

				<Flex direction="column">
					{isScenario ? (
						<TextFieldWithFixedPattern
							value={name || ""}
							onChange={setName}
							pattern={/_scenario$/}
							setPatternFunc={(value) => value + "_scenario"}
							validationState={nameValidationState}
							label={<FrameNameFieldLabel />}
							width=""
						/>
					) : (
						<TextField
							value={name || ""}
							onChange={setName}
							validationState={nameValidationState}
							label={<FrameNameFieldLabel />}
							width=""
						/>
					)}
					{validationErrors.map((x) => (
						<span key={x} className="error-text">{`• ${x}`}</span>
					))}
					{validationWarnings.map((x) => (
						<span key={x} className="warning-text">
							<Alert aria-label="Warning" color="notice" size="XS" />
							<span>{`${x}`}</span>
						</span>
					))}
				</Flex>

				<TextArea
					value={definition}
					onChange={setDefinition}
					label={<FrameDefinitionFieldLabel />}
					minHeight="size-2000"
					width=""
				/>

				<Flex direction="row">
					<Checkbox
						isSelected={isScenario}
						onChange={(value) => {
							if (value) {
								setName(name + "_scenario");
								setIsScenario(value);
							} else {
								setName(name.replace(/_scenario$/, ""));
								setIsScenario(false);
							}
						}}
						UNSAFE_style={{ paddingRight: 0 }}
					>
						This is a scenario frame.
					</Checkbox>
					<FrameScenarioFieldLabel />
				</Flex>

				<DialogTrigger isOpen={isConfirmationOpen}>
					<></>
					<AddLanguageConfirmationDialog
						frame={errorData.existingFrame}
						newLanguage={languages ? languages[0] : null}
						close={() => setConfirmationOpen(false)}
					/>
				</DialogTrigger>

				<ButtonGroup align="center">
					<Button
						variant="cta"
						marginTop="size-150"
						onPress={onPressSave}
						isDisabled={!definition || !name || nameValidationState !== "valid"}
					>
						SAVE FRAME DEFINITION
					</Button>
				</ButtonGroup>
			</Flex>
		</Flex>
	);
}

export default FrameDefinitionScreen;
