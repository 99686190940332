import React from "react";
import {
	Button,
	Divider,
	Flex,
	Heading,
	Image,
	Text,
} from "@adobe/react-spectrum";
import { useAuth0 } from "@auth0/auth0-react";

import Logo from "../img/logo.png";

function WelcomeScreen() {
	const { loginWithRedirect } = useAuth0();

	return (
		<Flex
			flex={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			gap="size-150"
			margin="size-200"
			// height="90vh"
		>
			<Heading level={1} marginTop="size-50" marginBottom="size-0">
				Welcome to
			</Heading>
			<Flex width="size-1200" height="size-1200">
				<Image src={Logo} alt="Lutma logo" />
			</Flex>
			<Divider size="M" marginTop="size-100" />
			<Text>
				Lutma is a frame maker tool. It's purpose is to allow people to
				contribute frames to Global FrameNet. If you understood the two previous
				sentences, go ahead and
			</Text>
			<Button
				variant="cta"
				marginY="size-100"
				onPress={() => loginWithRedirect()}
			>
				Start using
			</Button>
			<Text>If not, please watch this video tutorial.</Text>
			<Button
				marginY="size-100"
				onPress={() =>
					window.open("https://www.youtube.com/watch?v=nGPyfyUtqqc", "_blank")
				}
			>
				Video tutorial
			</Button>
		</Flex>
	);
}

export default WelcomeScreen;
