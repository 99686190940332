import React from "react";
import { Button, Divider, Flex, Heading, Text } from "@adobe/react-spectrum";
import { useHistory } from "react-router";

import Tags from "../components/Tags";
import { getUtcString } from "../utils/date";

export default function FramePreview({ frame, isLast }) {
	const history = useHistory();

	const luCount = frame.lus.length;
	const coreFeCount = frame.fes.filter(
		(fe) => fe.type === "core" || fe.type === "core-unexpressed"
	).length;
	const relationCount = frame.relations ? frame.relations.length : "-";

	function onPress() {
		history.push(
			frame.isDraft ? `frame/${frame.idFrame}/edit` : `frame/${frame.idFrame}`
		);
	}

	return (
		<Flex direction="column">
			<Flex alignItems="center" justifyContent="start">
				<Heading level={4} UNSAFE_style={{ wordBreak: "break-all" }}>
					{frame.name}
				</Heading>
				<Flex flex={1} justifyContent="end">
					<Button variant="cta" onPress={onPress}>
						<span className="small-button-text">
							{frame.isDraft ? "Edit" : "Preview"}
						</span>
					</Button>
				</Flex>
			</Flex>
			<Text>
				{frame.description.length > 130
					? frame.description.slice(0, 130) + "..."
					: frame.description}
			</Text>
			<Flex marginTop="size-100" gap="size-150">
				<Flex>
					<span className="bold">LUs:&nbsp;</span>
					{luCount}
				</Flex>
				<Flex>
					<span className="bold">Core FEs:&nbsp;</span>
					{coreFeCount}
				</Flex>
				<Flex>
					<span className="bold">Relations:&nbsp;</span>
					{relationCount}
				</Flex>
			</Flex>
			<Flex marginTop="size-100" alignItems="center">
				<Tags items={[frame.isDraft ? "Draft" : "Published"]} />
				<Flex flex={1} justifyContent="end">
					<Text>
						<span className="small-text">{getUtcString(frame.createdAt)}</span>
					</Text>
				</Flex>
			</Flex>
			{!isLast && <Divider marginTop="size-150" size="M" />}
		</Flex>
	);
}
