import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
	ActionGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Header,
	Item,
	Text,
} from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import Border from "@spectrum-icons/workflow/Border";
import Help from "@spectrum-icons/workflow/HelpOutline";
import LogOut from "@spectrum-icons/workflow/LogOut";
import Search from "@spectrum-icons/workflow/Search";
import TextIcon from "@spectrum-icons/workflow/Text";
import Info from "@spectrum-icons/workflow/InfoOutline";

const menuItems = [
	{
		key: "create/frame",
		label: "Create",
		icon: Add,
	},
	{
		key: "my-frames",
		label: "My Frames",
		icon: Border,
	},
	{
		key: "search-frames",
		label: "Search Frames",
		icon: Search,
		openNewTab: true,
	},
	{
		key: "my-lus",
		label: "My Lexical Units",
		icon: TextIcon,
	},
	{
		key: "search-lus",
		label: "Search Lexical Units",
		icon: Search,
		openNewTab: true,
	},
	{
		key: "help",
		label: "Help",
		icon: Help,
	},
	{
		key: "about",
		label: "About",
		icon: Info,
	},
	{
		key: "logout",
		label: "Logout",
		icon: LogOut,
	},
];

function NavigationDialog({ close }) {
	const history = useHistory();
	const {
		user,
		isAuthenticated,
		isLoading,
		logout,
	} = useAuth0();

	function onAction(key) {
		// close menu to prevent bugs.
		close();

		if (key === "logout") {
			logout({ returnTo: window.location.origin });
		} else {
			const menu = menuItems.find((item) => item.key === key);

			if (menu) {
				if (menu.handler) {
					return menu.handler();
				}
				if (menu.openNewTab) {
					return window.open(`/${key}`, '_blank');
				}
			}
			
			history.push(`/${key}`);
		}
	}

	return (
		<Dialog UNSAFE_className="menu-wrapper">
			<Heading>Lutma v.0.0</Heading>
			<Header>
				<span className="small-text">
					{isLoading
						? "Loading..."
						: isAuthenticated
						? user.name
						: "Not logged in"}
				</span>
			</Header>
			<Divider />
			<Content>
				<ActionGroup
					isJustified
					isQuiet
					density="compact"
					orientation="vertical"
					items={menuItems}
					onAction={onAction}
				>
					{(item) => (
						<Item key={item.key} textValue={item.label}>
							{React.createElement(item.icon, {
								size: "S",
								marginEnd: "size-100",
							})}
							<Text>{item.label}</Text>
						</Item>
					)}
				</ActionGroup>
			</Content>
		</Dialog>
	);
}

export default NavigationDialog;
