import React, { useEffect, useState } from "react";
import { ActionGroup, Item, Text, Well } from "@adobe/react-spectrum";

import Asterisk from "@spectrum-icons/workflow/Asterisk";

const isCore = (fe) => fe.type === "core" || fe.type === "unexpressed";

function getSortedFes(fes) {
	const copy = fes.slice();

	copy.sort((a, b) => {
		const diff = isCore(b) - isCore(a);

		if (diff !== 0) {
			return diff;
		}

		if (a.name < b.name) {
			return -1;
		}

		return 1;
	});

	return copy;
}

function findFe(fes, selected) {
	return selected
		? fes.find((fe) => fe.idFrameElement === Number(selected))
		: null;
}

export default function FeViewer({ fes }) {
	const [selectedFe, setSelectedFe] = useState(null);
	const [selectedKeys, setSelectedKeys] = useState(new Set());

	useEffect(() => {
		if (fes && fes.length > 0 && !findFe(fes, selectedFe)) {
			const newFeId = String(getSortedFes(fes)[0].idFrameElement);

			setSelectedFe(newFeId);
			setSelectedKeys(new Set([newFeId]));
		}
	}, [fes, selectedFe, setSelectedFe, setSelectedKeys]);

	const filteredFes = (fes || []).filter((fe) => fe.isSelected === null || fe.isSelected);

	if (filteredFes.length === 0) {
		return null;
	}

	return (
		<>
			<Well>
				<Text>{findFe(fes, selectedFe)?.description || ""}</Text>
			</Well>
			<ActionGroup
				selectionMode="single"
				selectedKeys={selectedKeys}
				onAction={setSelectedFe}
				onSelectionChange={setSelectedKeys}
				marginTop="size-150"
			>
				{getSortedFes(filteredFes).map((fe) => {
					return (
						<Item key={fe.idFrameElement} textValue={fe.name}>
							{isCore(fe) && <Asterisk size="S" />}
							<Text>{fe.name}</Text>
						</Item>
					);
				})}
			</ActionGroup>
		</>
	);
}
