export default [
	{
		name: "rel_inheritance",
		labels: ["inherits from", "inherited by"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame inherits from another, the daughter frame must include corresponding frame elements for each of the core and peripheral elements in the mother frame, and at least one of them should be a more specific version of the corresponding element in the mother frame.",
	},
	{
		name: "rel_subframe",
		labels: ["is a subframe of", "has subframe"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame is a subframe of another, the daughter frame may include corresponding frame elements for some of the elements in the mother frame, but it is not mandatory.",
	},
	{
		name: "rel_using",
		labels: ["uses", "is used by"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame uses another, the daughter frame may include corresponding frame elements for some of the elements in the mother frame, but it is not mandatory.",
	},
	{
		name: "rel_inchoative_of",
		labels: ["is inchoactive of", "has inchoactive"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame is an inchoactive alternation of another, the daughter frame frame will include corresponding frame elements for most (if not all) of the elements in the mother frame.",
	},
	{
		name: "rel_causative_of",
		labels: ["is causative of", "has causative"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame is a causative alternation of another, the daughter frame will include corresponding frame elements for most (if not all) of the elements in the mother frame. The daughter frame will also include an additional element indicating the cause or agent.",
	},
	{
		name: "rel_precedes",
		labels: ["precedes", "is preceded by"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame precedes another, the daughter frame may include corresponding frame elements for some of the elements in the mother frame, but it is not mandatory.",
	},
	{
		name: "rel_perspective_on",
		labels: ["is a perspective of", "is perspectivized by"],
		description:
			"Every relation between frames involves relations between the frame elements in them. When a frame is a perspective_on another, the daughter frame will include corresponding frame elements for at least some of the elements in the non-perspectivized mother frame, the ones that are profiled in the perspective.",
	},
];
