import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FeCoreHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>What are core FEs?</Heading>
			<Divider />
			<Content>
				<Text>
					Core frame elements are necessary for the frame to be instantiated.
					For example, the <span className="frame-name">Touring</span> frame
					features three core FEs: Tourist, Attraction and Place. For the idea
					of <span className="frame-name">Touring</span> to be evoked, the three
					of them must be recruited.
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() =>
						window.open("https://www.youtube.com/watch?v=hhAgJPt8oIU", "_blank")
					}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FeCoreHelpDialog;
