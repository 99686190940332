export default [
	{
			id: "rel_coreset",
			name: "Core Set"
	},
	{
			id: "rel_excludes",
			name: "Excludes"
	},
	{
			id: "rel_requires",
			name: "Requires"
	}
]