export function setBodyBg() {
	const provider = document.querySelector('#root > div');
	
	if (window.getComputedStyle) {
		document.body.style.backgroundColor =
			window.getComputedStyle(provider).backgroundColor;

	}
}

export function removeProviderIsolation() {
	const provider = document.querySelector('#root > div');
	provider.style.isolation = 'auto';
}

export function setProviderIsolation() {
	const provider = document.querySelector('#root > div');
	provider.style.isolation = 'isolate';
}

export function setAutocapitalize(ref) {
	ref.current.getInputElement().setAttribute('autocapitalize', 'off');
}
