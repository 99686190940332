import React from "react";
import { Link } from "react-router-dom";
import {
	Content,
	Flex,
	Heading,
	IllustratedMessage,
	Link as SpectrumLink,
} from "@adobe/react-spectrum";

import NotFound from '@spectrum-icons/illustrations/NotFound';

function NotFoundScreen() {
	return (
		<Flex
			flex={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			gap="size-150"
			margin="size-200"
			height="90vh"
		>
			<IllustratedMessage>
				<NotFound />
				<Heading>Error 404: Page not found</Heading>
				<Content>This page isn't available. Try checking the URL or visit a different page.</Content>
				<SpectrumLink marginTop="size-100">
					<Link to={`/`}>Home</Link>
				</SpectrumLink>
			</IllustratedMessage>
		</Flex>
	);
}

export default NotFoundScreen;
