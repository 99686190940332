import useSWR from "swr";

import FE_TYPES from "../constants/frameElementTypes";
import FE_RELATION_TYPES from "../constants/frameElementRelationTypes";

const FE_TYPE_MAP = Object.assign(
	{},
	...FE_TYPES.map((x) => ({ [x.id]: x.name }))
);
const FE_RELATION_MAP = Object.assign(
	{},
	...FE_RELATION_TYPES.map((x) => ({ [x.id]: x.name }))
);

export default function useFeRelations(frameId) {
	const { data: frame, error: errorFrame } = useSWR(`/frames/${frameId}?_embed=fes`);
	const { data: feRelations, error: errorFeRelations } = useSWR(`/frames/${frameId}/ferelations`);
	const { data: relationTypes, error: errorRelationTypes } = useSWR("/relationsFrameElement");

	let relations;

	if (frame && feRelations && relationTypes) {
		const fes = Object.assign(
			{},
			...frame.fes.map((fe) => ({ [fe.idFrameElement]: fe }))
		);

		relations = feRelations.map((relation) => {
			let fe1 = fes[parseInt(relation.idFrameElement1)]
			let fe2 = fes[parseInt(relation.idFrameElement2)]
			let type = relationTypes.find(
				(type) => type.idRelationType === parseInt(relation.idRelationType)
			);

			return {
				...relation,
				isActive: Boolean(relation.isActive),
				type: FE_RELATION_MAP[type.name],
				fe1Name: fe1.name.toUpperCase(),
				fe2Name: fe2.name.toUpperCase(),
				fe1Type: FE_TYPE_MAP[fe1.type],
				fe2Type: FE_TYPE_MAP[fe2.type],
				isInvalid: !fe1.isSelected || !fe2.isSelected
			};
		})
	} else {
		relations = [];
	}

	return {
		data: relations,
		error: errorFrame || errorFeRelations || errorRelationTypes
	}
}