import React from 'react';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from '@adobe/react-spectrum';

function DefinitionHelpDialog({ close } ) {
	return (
		<Dialog>
			<Heading>Definition</Heading>
			<Divider />
			<Content>
				<Text>Define the LU in the target language and, if such language is not English, provide an English translation for the LU.</Text>
			</Content>
			<ButtonGroup>
				<Button variant="cta" onPress={close}>Got it!</Button>
			</ButtonGroup>
		</Dialog>
	)
}

export default DefinitionHelpDialog;