import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FrameRootHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>What are root frame types?</Heading>
			<Divider />
			<Content>
				<Text>
					Frame root types indicate general features that can be applied to a
					frame depending of the kind of concept it represents. There are
					non-core frame elements that tend to correlate with root types. For
					example, eventive frames tend to include elements for Place, Time and
					Frequency, while attribute frames include elements such as Degree.
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() => window.open("https://youtu.be/828-Tgs_TTQ", "_blank")}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FrameRootHelpDialog;
