import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { DialogTrigger, View } from "@adobe/react-spectrum";

import UiStateContext from "../contexts/UiStateContext";
import { removeProviderIsolation, setProviderIsolation } from "../utils/dom";

const TutorialTrigger = observer((props) => {
	const state = useContext(UiStateContext);

	const { id, children, isHidden, ...triggerProps } = props;
	const isOpen = state.showTutorial[id] && !isHidden;

	useEffect(() => {
		if (isOpen) removeProviderIsolation();
	});

	const onOpenChange = () => {
		state.setSeen(id);
		setProviderIsolation();
	};

	if (isOpen) {
		return (
			<DialogTrigger
				type="popover"
				placement="end"
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				{...triggerProps}
			>
				<View position="relative" zIndex={2}>
					{children[0]}
				</View>
				{children[1]}
			</DialogTrigger>
		);
	} else {
		return children[0];
	}
});

export default TutorialTrigger;
