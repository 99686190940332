import React, { useState } from "react";
import { ActionButton, Flex } from "@adobe/react-spectrum";

import TutorialTrigger from "./TutorialTrigger";

function Accordion({
	title,
	isExpanded,
	defaultIsExpanded,
	tutorialId,
	dialog,
	renderAction,
	onChange,
	children,
}) {
	const [isExpandedState, setIsExpandedState] = useState(defaultIsExpanded);

	const isHidden = isExpanded !== undefined ? !isExpanded : !isExpandedState;

	const action = (
		<ActionButton
			onPress={() => {
				if (onChange) onChange(isExpanded);
				setIsExpandedState(!isExpandedState);
			}}
			marginBottom="size-150"
		>
			{title}
		</ActionButton>
	);

	return (
		<Flex direction="column">
			<Flex justifyContent="space-between">
				{tutorialId && dialog ? (
					<TutorialTrigger id={tutorialId}>
						{action}
						{dialog}
					</TutorialTrigger>
				) : (
					action
				)}

				{renderAction && renderAction(isHidden)}
			</Flex>
			<Flex direction="column" isHidden={isHidden}>
				{children}
			</Flex>
		</Flex>
	);
}

export default Accordion;
