import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import FeRelationHelpDialog from './FeRelationHelpDialog'

function FeRelationFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>There is a FE-to-FE relation of</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <FeRelationHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FeRelationFieldLabel;