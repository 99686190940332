import React, { useState } from "react";
import DataAdd from "@spectrum-icons/workflow/DataAdd";
import { useHistory, Link } from "react-router-dom";
import {
	Flex,
	Heading,
	ProgressBar,
	Text,
	ActionButton,
	Link as SpectrumLink,
} from "@adobe/react-spectrum";

import Accordion from "./Accordion";
import TutorialTrigger from "./TutorialTrigger";
import AddLuToFrameCoachDialog from "./tutorials/lu/AddLuToFrameCoachDialog";
import LuPreviewDialog from "./tutorials/lu/LuPreviewCoachDialog";
import useQuery from "../hooks/useQuery";

const loadingLabel = {
	match: "Searching LUs...",
	synonyms: "Searching synonym database...",
	global: "Searching in other languages...",
};

function LuSearchResultList({ title, data, type, isHidden }) {
	const history = useHistory();
	const query = useQuery();

	const [expandedIdx, setExpandedIdx] = useState();

	const { q, pos, lang } = query;

	if (!data) {
		return (
			<ProgressBar
				label={loadingLabel[type]}
				isIndeterminate
				marginTop="size-150"
			/>
		);
	}

	let items = data;

	if (type === "global") {
		// Remove LUs that should've been shown in the first result screen
		items = data.filter((lu) => lu.idLanguage !== lang);
	}

	if (items.length === 0) {
		return (
			<>
				<Heading level={5} margin="size-0">
					NO MATCHING LEXICAL UNIT
				</Heading>
				<Text>
					We could not find any lexical unit matching your query. What would you
					like to do?
				</Text>
			</>
		);
	}

	items = items.map((lu) => ({
		id: lu.idLU,
		name: `${lu.name} @ ${lu.frame.name}`,
		frameId: lu.frame.idFrame,
		definition: lu.frame.description,
	}));

	const renderAction = (frameId, isHidden) => {
		return (
			<TutorialTrigger id="addLu" isHidden={isHidden}>
				<ActionButton
					isHidden={isHidden}
					onPress={() =>
						history.push(
							`/create/lu?name=${q}&pos=${pos}&lang=${lang}&frameId=${frameId}`
						)
					}
				>
					<DataAdd size="S" />
				</ActionButton>
				<AddLuToFrameCoachDialog />
			</TutorialTrigger>
		);
	};

	return (
		<Flex direction="column" gap="size-150" isHidden={isHidden}>
			<Heading level={5} margin="size-0">
				{title}
			</Heading>
			<Flex direction="column">
				{items.map((item, i) => {
					const accordionProps = {
						key: item.id,
						title: item.name,
						isExpanded: expandedIdx === i,
						onChange: () => setExpandedIdx(expandedIdx !== i ? i : null),
					};

					if (type === "match" && i === 0) {
						accordionProps.tutorialId = "luPreview";
						accordionProps.dialog = <LuPreviewDialog />;
					} else if (type !== "match") {
						accordionProps.renderAction = (isHidden) =>
							renderAction(item.frameId, isHidden);
					}

					return (
						<Accordion {...accordionProps}>
							<Text>{item.definition}</Text>
							<SpectrumLink marginY="size-150">
								<Link to={`/frame/${item.frameId}`} target="_blank">
									See full frame description
								</Link>
							</SpectrumLink>
						</Accordion>
					);
				})}
			</Flex>
		</Flex>
	);
}

export default LuSearchResultList;
