import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FrameDefinitionHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>How to create frame definitions?</Heading>
			<Divider />
			<Content>
				<Text>
					Frame definitions have the purpose of providing a general,
					prototypical description of the event, state, entity, relation or
					attribute modeled in the frame. Definitions must then mention the key
					participants and props in the frame, that is, the key Frame Elements,
					and describe how they interact. Everytime a Frame Element is mentioned
					in the definition, it must be preceeded by a #.
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() => window.open("https://youtu.be/828-Tgs_TTQ", "_blank")}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FrameDefinitionHelpDialog;
