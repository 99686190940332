import React from "react";
import { Button, Divider, Flex, Heading, Text } from "@adobe/react-spectrum";
import { useHistory } from "react-router";

import Tags from "./Tags";
import { getUtcString } from "../utils/date";

export default function LuPreview({ lu, isLast }) {
	const history = useHistory();

	function onPress() {
		history.push(lu.isDraft ? `lu/${lu.idLU}/edit` : `lu/${lu.idLU}`);
	}

	return (
		<Flex direction="column">
			<Flex alignItems="center" justifyContent="start">
				<Flex flex={1}>
					<Heading
						level={4}
						UNSAFE_style={{ wordBreak: "break-all" }}
					>{`${lu.name} @ ${lu.frame.name}`}</Heading>
				</Flex>
				<Flex>
					<Button variant="cta" onPress={onPress}>
						<span className="small-button-text">
							{lu.isDraft ? "Edit" : "Preview"}
						</span>
					</Button>
				</Flex>
			</Flex>
			<Text>{lu.description}</Text>
			<Flex marginTop="size-100" gap="size-150">
				<Flex>
					<span className="bold">Incorporated FE:&nbsp;</span>
					{lu?.fe?.name || "-"}
				</Flex>
			</Flex>
			<Flex marginTop="size-100" alignItems="center">
				<Tags items={[lu.isDraft ? "Draft" : "Published"]} />
				<Flex flex={1} justifyContent="end">
					<Text>
						<span className="small-text">{getUtcString(lu.createdAt)}</span>
					</Text>
				</Flex>
			</Flex>
			{!isLast && <Divider marginTop="size-150" size="M" />}
		</Flex>
	);
}
