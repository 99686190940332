import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Content, Dialog, Divider, Heading } from "@adobe/react-spectrum";

import UiStateContext from "../contexts/UiStateContext";
import LanguageComboBox from "../components/LanguageComboBox";

const LuIndexFilterDialog = observer(function LuIndexFilterDialog() {
	const state = useContext(UiStateContext);

	return (
		<Dialog>
			<Heading>Filters</Heading>
			<Divider />
			<Content>
				<LanguageComboBox
					label="Language"
					selectedKey={state.searchLanguageId || state.defaultLanguageId}
					onSelectionChange={(id) => {
						state.searchLanguageId = id;
					}}
				/>
			</Content>
		</Dialog>
	);
});

export default LuIndexFilterDialog;
