import React from "react";
import { ActionButton, DialogTrigger, Flex } from "@adobe/react-spectrum";
import Cancel from "@spectrum-icons/workflow/Cancel";

import PageHeading from "./PageHeading";

import CancelConfirmationDialog from "../dialogs/CancelConfirmationDialog";

function ActionHeader(props) {
	let { title, description, align, children, isCancelable = true } = props;

	if (typeof children === "string") {
		title = title || children;
		children = null;
	}

	return (
		<Flex flex={1} alignItems="center">
			<PageHeading title={title} description={description} alignItems={align} />
			{children ? (
				children
			) : isCancelable ? (
				<DialogTrigger>
					<ActionButton>
						<Cancel size="S" />
					</ActionButton>
					{(close) => <CancelConfirmationDialog close={close} />}
				</DialogTrigger>
			) : null}
		</Flex>
	);
}

export default ActionHeader;
