import React from "react";
import useSWR from "swr";
import dayjs from "dayjs";
import { ActionButton, DialogTrigger, Flex, Heading, Text, Well } from "@adobe/react-spectrum";
import { useParams, useRouteMatch } from "react-router-dom";

import Delete from "@spectrum-icons/workflow/Delete";

import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";

import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog";

import "@spectrum-web-components/accordion/sp-accordion.js";
import "@spectrum-web-components/accordion/sp-accordion-item.js";

function LuReportScreen() {
	const { id: luId } = useParams();
	const isEdit = useRouteMatch("/lu/:id/edit");

	const { data: lu } = useSWR(`/lus/${luId}?_embed=fe&_expand=frame`);

	if (!lu) {
		return (
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<Heading level={3} margin="size-0">
					Loading...
				</Heading>
			</Flex>
		);
	}

	const time = dayjs().format("D MMM YYYY [at] H:mm ([UTC])");

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				{isEdit && (
					<DialogTrigger>
						<ActionButton>
							<Delete size="S" color="negative" />
						</ActionButton>
						{(close) => <DeleteConfirmationDialog close={close} />}
					</DialogTrigger>
				)}
			</AppBar>
			<Flex
				flex={1}
				direction="column"
				gap="size-150"
				UNSAFE_className="sp-accordion"
			>
				<PageHeading
					subtitle="This is the report for the Lexical Unit"
					subtitle2={`Evoking [${lu.frame.name}]`}
					marginTop="size-100"
					marginStart="size-200"
					marginEnd="size-200"
				>
					{lu.name}
				</PageHeading>
				<sp-accordion allow-multiple>
					<sp-accordion-item label="Definition" open>
						<Well>
							<Text>{lu.description}</Text>
						</Well>
					</sp-accordion-item>

					<sp-accordion-item label="Incorporated FE" open>
						<Well>
							{lu?.fe?.name ? (
								<Text>{`${lu.fe.name}: ${lu.fe.description}`}</Text>
							) : (
								<Text>{"-"}</Text>
							)}
						</Well>
					</sp-accordion-item>

					<sp-accordion-item label="Example Sentence" open>
						<Well>
							<Text>{lu.exampleText || "-"}</Text>
						</Well>
					</sp-accordion-item>
				</sp-accordion>

				<Text margin="size-200">
					<span className="very-small-text">{`Created on ${time}`}</span>
				</Text>
			</Flex>
		</Flex>
	);
}

export default LuReportScreen;
