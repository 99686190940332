export default [
	{
		id: 'core',
		name: 'Core',
	},
	{
		id: 'unexpressed',
		name: 'Core unexpressed',
	},
	{
		id: 'peripheral',
		name: 'Peripheral',
	},
	{
		id: 'extrathematic',
		name: 'Extra-thematic',
	},
]