import React, { useContext, useState } from "react";
import useSWRInfinite from "swr/infinite";
import { observer } from "mobx-react-lite";
import {
	ActionButton,
	DialogTrigger,
	Flex,
	ProgressCircle,
	SearchField,
	Text,
} from "@adobe/react-spectrum";

import UiStateContext from "../contexts/UiStateContext";
import ActionHeader from "../components/ActionHeader";
import LuIndex from "../components/LuIndex";
import LuIndexFilterDialog from "../dialogs/LuIndexFilterDialog";
import useQuery from "../hooks/useQuery";

import Close from "@spectrum-icons/workflow/Close";
import Filter from "@spectrum-icons/workflow/Filter";

const LIMIT = 15;

const FrameIndexScreen = observer(function FrameIndexScreen() {
	const { q } = useQuery();

	const uiState = useContext(UiStateContext);

	const [searchText, setSearchText] = useState(q || "");
	const [searchInputText, setSearchInputText] = useState(q || "");
	const [searchTimeout, setSearchTimeout] = useState(null);

	const { data, error, size, isValidating, setSize } = useSWRInfinite(
		(page, previous) => {
			if (!searchText || (page && !previous.length)) return null;

			const offset = page * LIMIT;

			return `/lus?q=${searchText}&limit=${
				offset + LIMIT
			}&offset=${offset}&idLanguage=${uiState.searchLanguageId || uiState.defaultLanguageId}`;
		},
		{ initialSize: 1 }
	);

	const isLoadingInitialData = !data && !error;
	const isLoadingMore =
		isLoadingInitialData ||
		(size > 0 && data && typeof data[size - 1] === "undefined");
	const isEmpty = data?.[0]?.length === 0;
	const isReachingEnd =
		isEmpty || (data && data[data.length - 1]?.length < LIMIT);
	const hasNextPage = isLoadingMore || !isReachingEnd;

	function updateSearchText(newValue) {
		clearTimeout(searchTimeout);
		setSearchInputText(newValue);
		setSearchTimeout(setTimeout(() => setSearchText(newValue), 400));

		window.history.replaceState(null, document.title, `?q=${newValue}`);
	}

	return (
		<Flex flex={1} direction="column">
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<ActionHeader title="Lexical Unit Index" isCancelable={false}>
					<DialogTrigger isDismissable>
						<ActionButton isQuiet>
							<Filter size="S" />
						</ActionButton>
						<LuIndexFilterDialog />
					</DialogTrigger>
					<ActionButton onPress={() => window.close()} isQuiet>
						<Close size="S" />
					</ActionButton>
				</ActionHeader>
				<SearchField
					value={searchInputText}
					onChange={updateSearchText}
					label="Searching Global FrameNet"
					width=""
				/>
				{!searchText ? (
					<Text marginTop="size-200">Enter a search query to see results.</Text>
				) : isLoadingInitialData ? (
					<ProgressCircle
						aria-label="Loading..."
						isIndeterminate
						alignSelf="center"
						marginTop="size-150"
					/>
				) : data[0].length > 0 ? (
					<LuIndex
						data={data}
						hasNextPage={hasNextPage}
						isNextPageLoading={isValidating}
						loadNextPage={() => setSize(size + 1)}
					/>
				) : (
					<Text marginTop="size-200">No results found.</Text>
				)}
			</Flex>
		</Flex>
	);
});

export default FrameIndexScreen;
