import { makeAutoObservable } from "mobx";

class UiState {
	showTutorial = {
		luPreview: true,
		addLu: true,
	};

	defaultLanguageId = null;

	searchLanguageId = null;

	user = null;

	constructor() {
		makeAutoObservable(this);

		const showTutorial = JSON.parse(localStorage.getItem("UI.showTutorial"));
		const defaultLanguageId = parseInt(localStorage.getItem("UI.defaultLanguageId"));

		if (showTutorial) {
			this.showTutorial = showTutorial;
		}

		if (defaultLanguageId) {
			this.defaultLanguageId = defaultLanguageId;
		}
	}

	setSeen(tutorial) {
		this.showTutorial[tutorial] = false;
		localStorage.setItem(
			"UI.showTutorial",
			JSON.stringify({ ...this.showTutorial })
		);
	}

	setUserData(user) {
		this.user = user;
		// This is the case only before the user is fully registered
		if (user.languages.length > 0) {
			this.defaultLanguageId = user.languages[0].idLanguage;
		}
		localStorage.setItem("UI.defaultLanguageId", this.defaultLanguageId);
	}
}

export default UiState;
