import React from "react";
import { Flex, Heading, Text } from "@adobe/react-spectrum";

function PageHeading(props) {
	let { title, subtitle, subtitle2, children, ...flexProps } = props;

	function renderHeading() {
		if (!children || typeof children === "string") {
			return (
				<Heading
					level={3}
					margin="size-0"
					UNSAFE_style={{ wordBreak: "break-word" }}
				>
					{title || children}
				</Heading>
			);
		}

		return children;
	}

	return (
		<Flex flex={1} direction="column" {...flexProps}>
			{subtitle && <span className="small-text">{subtitle}</span>}
			<Flex>{renderHeading()}</Flex>
			{subtitle2 && <Text>{subtitle2}</Text>}
		</Flex>
	);
}

export default PageHeading;
