import React, { useContext, useState } from "react";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import {
	ActionButton,
	Button,
	ButtonGroup,
	DialogTrigger,
	Flex,
	Item,
	Picker,
	TextArea,
	TextField,
} from "@adobe/react-spectrum";

import Cancel from "@spectrum-icons/workflow/Cancel";

import useQuery from "../hooks/useQuery";
import usePosTypes from "../hooks/usePosTypes";
import ApiContext from "../contexts/ApiContext";
import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";
import LuDefinitionFieldLabel from "../components/tutorials/lu/LuDefinitionFieldLabel";
import LuExampleSentenceFieldLabel from "../components/tutorials/lu/LuExampleSentenceFieldLabel";
import LuIncorporatedFeFieldLabel from "../components/tutorials/lu/LuIncorporatedFeFieldLabel";

import CancelConfirmationDialog from "../dialogs/CancelConfirmationDialog";

function LuCreationScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();
	const query = useQuery();

	const { data: posTags } = usePosTypes();
	const { data: frame } = useSWR(`/frames/${query.frameId}?_embed=fes`);

	const [luText, setLuText] = useState(query.name);
	const [posId, setPosId] = useState(query.pos);

	const [feId, setFeId] = useState(null);
	const [exampleText, setExampleText] = useState("");
	const [definitionText, setDefinitionText] = useState("");

	async function onPressSave() {
		await api.post(`/frames/${query.frameId}/lus`, {
			name: luText,
			pos: posId,
			description: definitionText,
			exampleText: exampleText,
			isDraft: true,
			idLanguage: query.lang,
			idFrameElement: feId,
			idFrame: query.frameId,
		});

		history.push("/my-lus");
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<DialogTrigger>
					<ActionButton>
						<Cancel size="S" />
					</ActionButton>
					{(close) => <CancelConfirmationDialog close={close} />}
				</DialogTrigger>
			</AppBar>
			<Flex flex={1} direction="column" gap="size-115" margin="size-200">
				<PageHeading subtitle="You are adding a new LU to the frame">
					{frame ? frame.name : "Loading..."}
				</PageHeading>

				<TextField
					value={luText}
					onChange={setLuText}
					label="Lexical Unit"
					isDisabled={query.name}
					isRequired
					width=""
				/>

				<Picker
					label="As a"
					labelPosition="side"
					selectedKey={posId}
					onSelectionChange={setPosId}
					validationState="valid"
					items={posTags}
					isDisabled={query.pos}
					isRequired
				>
					{(item) => <Item key={item.idPOS}>{item.name}</Item>}
				</Picker>

				<TextArea
					label={<LuDefinitionFieldLabel />}
					value={definitionText}
					onChange={setDefinitionText}
					isRequired
					necessityIndicator=""
					width=""
				/>

				<Picker
					label={<LuIncorporatedFeFieldLabel />}
					selectedKey={feId}
					onSelectionChange={setFeId}
					validationState="valid"
					items={
						frame
							? frame.fes.filter((fe) => fe.type === "core" && fe.isSelected)
							: []
					}
					isLoading={!frame}
					width=""
				>
					{(item) => <Item key={item.idFrameElement}>{item.name}</Item>}
				</Picker>

				<TextArea
					label={<LuExampleSentenceFieldLabel />}
					value={exampleText}
					onChange={setExampleText}
					isRequired
					necessityIndicator=""
					width=""
				/>

				<ButtonGroup align="end">
					<Button
						variant="cta"
						marginTop="size-150"
						onPress={onPressSave}
						isDisabled={!(luText && posId && definitionText && exampleText)}
					>
						Save Lexical Unit
					</Button>
				</ButtonGroup>
			</Flex>
		</Flex>
	);
}

export default LuCreationScreen;
