import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function LemmaHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>What is a lemma?</Heading>
			<Divider />
			<Content>
				<Text>
					Lemma is a form of a word that appears as an entry in a dictionary and
					is used to represent all the other possible forms. For example, the
					lemma "build" represents "builds", "building", "built", etc.
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() => window.open("https://youtu.be/D3BFI6y8ths", "_blank")}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default LemmaHelpDialog;
