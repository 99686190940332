import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	ButtonGroup,
	Flex,
	Heading,
	Radio,
	RadioGroup,
} from "@adobe/react-spectrum";

import useQuery from "../hooks/useQuery";
import AppBar from "../components/AppBar";
import LanguageComboBox from "../components/LanguageComboBox";
import FrameRootRadioLabel from "../components/tutorials/frame/FrameRootRadioLabel";
import ApiContext from "../contexts/ApiContext";

import frameTemplates from "../constants/frameTemplates";

function FrameRootScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();
	const { lemma, lang, pos, type: frameType } = useQuery();

	const [languageId, setlanguageId] = useState();
	const [selected, setSelected] = useState("");

	const isNonLexical = frameType === "nonlexical" && !lemma;

	async function onPressCreate() {
		const template = selected.toLowerCase();

		const { data: frame } = await api.post("frames", {
			root: template,
			isDraft: true,
		});

		if (lemma) {
			await api.post(`/frames/${frame.idFrame}/lus`, {
				name: lemma,
				pos: pos,
				description: "",
				exampleText: "",
				isDraft: Number(true),
				idLanguage: lang,
				idFrame: frame.idFrame,
			});
		}

		if (isNonLexical) {
			await api.post(`/frames/${frame.idFrame}/languages`, {
				idFrame: frame.idFrame,
				idLanguage: languageId,
			});
		}

		history.push(`/frame/${frame.idFrame}/definition`);
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" margin="size-200">
			<Heading level={3} margin="size-0">
				Creating a New Frame
			</Heading>

			{isNonLexical && (
				<LanguageComboBox
					label="In"
					labelPosition="side"
					onSelectionChange={setlanguageId}
				/>
			)}

			<RadioGroup
				value={selected}
				onChange={setSelected}
				label={<FrameRootRadioLabel />}
			>
				{frameTemplates.map((x, i) => (
					<Radio
						key={x.name}
						value={x.name}
						marginTop={i > 0 ? "size-40" : "size-0"}
						marginBottom={"size-40"}
					>
						<span className="paragraph-font-size bold-text">
							{x.name.toUpperCase()}
						</span>
						<span className="paragraph-font-size">{`: ${x.definition}`}</span>
					</Radio>
				))}
			</RadioGroup>

			<ButtonGroup align="center" marginTop="size-160">
				<Button variant="cta" onPress={onPressCreate} isDisabled={!selected}>
					{`CREATE ${selected.toUpperCase()} FRAME`}
				</Button>
			</ButtonGroup>
		</Flex>
		</Flex>
	);
}

export default FrameRootScreen;
