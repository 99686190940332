import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FrameElementNameHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>How to name frame elements?</Heading>
			<Divider />
			<Content>
				<Text>
					Frame Element names are chosen so as to help FrameNet users easily
					identify the role of each element in the scene. The level of
					generality of a frame reflects in the name of its elements, e.g.:
					Getting frame: Recipient, Theme, Source. Commerce_buy frame: Buyer,
					Goods, Seller.
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() =>
						window.open("https://www.youtube.com/watch?v=hhAgJPt8oIU", "_blank")
					}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FrameElementNameHelpDialog;
