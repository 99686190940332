import React from "react";
import UiState from "../stores/UiState";

const UiStateContext = React.createContext(null);

export default UiStateContext;

export function UiStateContextProvider({ children }) {
	return (
		<UiStateContext.Provider value={new UiState()}>
			{children}
		</UiStateContext.Provider>
	);
}
