import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import {
	ButtonGroup,
	Button,
	Flex,
	Item,
	Picker,
	TextArea,
} from "@adobe/react-spectrum";
import { useHistory, useParams } from "react-router-dom";

import PageHeading from "../components/PageHeading";
import ApiContext from "../contexts/ApiContext";

import FE_TYPES from "../constants/frameElementTypes";

function FeDefinitionScreen() {
	// TODO: what if the user leaves the screen?
	const api = useContext(ApiContext);

	const history = useHistory();
	const { id: frameId } = useParams();

	const { data: frame, mutate } = useSWR(`/frames/${frameId}?_embed=fes`);

	const [fes, setFes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (frame?.fes && fes.length === 0) {
			setFes(frame.fes.slice());
		}
	}, [frame, fes, setFes]);

	function onFeTypeChange(changedFe, key) {
		setFes(
			fes.map((fe) =>
				fe.idFrameElement === changedFe.idFrameElement
					? { ...changedFe, type: key }
					: fe
			),

			false
		);
	}

	function onDefinitionChange(changedFe, value) {
		setFes(
			fes.map((fe) =>
				fe.idFrameElement === changedFe.idFrameElement
					? { ...changedFe, description: value }
					: fe
			),
			false
		);
	}

	async function onPressSave() {
		const promises = fes.map((fe) =>
			api.patch(`/frames/${frameId}/fes/${fe.idFrameElement}`, fe)
		);

		setIsLoading(true);
		await Promise.all(promises);
		// await is necessary because the screen will be unmounted.
		await mutate();
		setIsLoading(false);

		history.push(`/frame/${frameId}/frame-elements`);
	}

	return (
		<Flex flex={1} direction="column" gap="size-150" margin="size-200">
			<PageHeading
				title={"Frame Elements"}
				subtitle={frame ? `${frame.name} has the following` : "Loading..."}
			/>

			{fes.map((fe) => (
				<Flex
					key={`fe-def-form-${fe.idFrameElement}`}
					direction="column"
					gap="size-100"
					marginTop="size-50"
				>
					<Picker
						label={
							<span>
								<span className="bold">{fe.name}</span> as
							</span>
						}
						items={FE_TYPES}
						selectedKey={fe.type}
						onSelectionChange={(key) => onFeTypeChange(fe, key)}
						validationState={fe.type ? "valid" : "invalid"}
						width=""
					>
						{(item) => <Item key={item.idFrameElement}>{item.name}</Item>}
					</Picker>
					<TextArea
						value={fe.description || ""}
						onChange={(value) => onDefinitionChange(fe, value)}
						validationState={fe.description ? "valid" : "invalid"}
						aria-label={`Frame element ${fe.name}`}
						placeholder="Definition"
						margintTop="size-100"
						width=""
					/>
				</Flex>
			))}

			<ButtonGroup align="center">
				<Button
					variant="cta"
					marginTop="size-150"
					onPress={onPressSave}
					isDisabled={
						isLoading || !frame || fes.some((fe) => !fe.type || !fe.description)
					}
				>
					SAVE DEFINITIONS
				</Button>
			</ButtonGroup>
		</Flex>
	);
}

export default FeDefinitionScreen;
