import React, { useState, useContext } from "react";
import { mutate } from "swr";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Flex,
	Heading,
	Item,
	Picker,
	TextArea,
	TextField,
} from "@adobe/react-spectrum";

import ActionHeader from "../components/ActionHeader";
import FeNameFieldLabel from "../components/tutorials/fe/FeNameFieldLabel";
import FeDefinitionFieldLabel from "../components/tutorials/fe/FeDefinitionFieldLabel";
import ApiContext from "../contexts/ApiContext";

import FE_TYPES from "../constants/frameElementTypes";

function CreateFeDialog({ frame, fes, close }) {
	const api = useContext(ApiContext);

	const [feName, setFeName] = useState("");
	const [feTypeId, setFeTypeId] = useState("");
	const [feDefinition, setFeDefinition] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const isDuplicate = Boolean(
		(fes || []).find((fe) => fe.name.toLowerCase() === feName.toLowerCase())
	);
	const isSaveDisabled =
		isLoading || isDuplicate || !feName || !feTypeId || !feDefinition;

	async function save() {
		setIsLoading(true);

		await api.post(`/frames/${frame.idFrame}/fes`, {
			idFrame: frame.idFrame,
			name: feName,
			description: feDefinition,
			type: feTypeId,
			isSelected: true,
		});

		mutate(`/frames/${frame.idFrame}?_embed=fes`);

		setIsLoading(false);

		close();
	}

	return (
		<Dialog mobileType="fullscreen">
			<Heading>
				<ActionHeader
					title={frame.name}
					description="You are adding a new FE to the frame"
					isCancelable={false}
				/>
			</Heading>
			<Divider />
			<Content>
				<Flex direction="column" gap="size-150">
					<Flex direction="column">
						<TextField
							value={feName}
							onChange={setFeName}
							label={<FeNameFieldLabel />}
							width=""
						/>
						{isDuplicate && (
							<span className="error-text">{`FE name already exists.`}</span>
						)}
					</Flex>

					<Picker
						label="As"
						labelPosition="side"
						items={FE_TYPES}
						selectedKey={feTypeId}
						onSelectionChange={setFeTypeId}
						validationState="valid"
					>
						{(item) => <Item key={item.id}>{item.name}</Item>}
					</Picker>

					<TextArea
						value={feDefinition}
						onChange={setFeDefinition}
						label={<FeDefinitionFieldLabel />}
						width=""
					/>
				</Flex>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={close}>
					Close
				</Button>
				<Button variant="cta" onPress={save} isDisabled={isSaveDisabled}>
					Save
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default CreateFeDialog;
