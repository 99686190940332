import React, { useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

let IS_AUTHENTICATED = true;
let HAS_TOKEN = null;

const AUTH_POOL_MS = 100;
const MAX_WAIT_MS = 2000;

function untilAuthenticated(waited = 0) {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			if (HAS_TOKEN) {
				resolve();
			} else if (waited < MAX_WAIT_MS) {
				untilAuthenticated(waited + AUTH_POOL_MS)
					.then(resolve)
					.catch(reject);
			} else {
				reject(new Error('Waited for authentication for too long.'));
			}
		}, AUTH_POOL_MS);
	});
}

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_DOMAIN
		? `https://${process.env.REACT_APP_API_DOMAIN}/api`
		: "localhost:8701",
});

axiosInstance.interceptors.request.use(
	async (config) => {
		await untilAuthenticated();
		// set token
		config.headers.common["Authorization"] =
			axiosInstance.defaults.headers.common["Authorization"];

		return config;
	},
	null,
	{ runWhen: () => IS_AUTHENTICATED && !HAS_TOKEN }
);

const ApiContext = React.createContext(null);

export default ApiContext;

export function ApiContextProvider({ children }) {
	const { isAuthenticated, getIdTokenClaims } = useAuth0();

	useEffect(() => {
		async function updateToken() {
			IS_AUTHENTICATED = isAuthenticated;

			if (isAuthenticated) {
				const token = await getIdTokenClaims();

				axiosInstance.defaults.headers.common[
					"Authorization"
				] = `Bearer ${token.__raw}`;

				HAS_TOKEN = true;
			}
		}

		updateToken();
	});

	return (
		<ApiContext.Provider value={axiosInstance}>{children}</ApiContext.Provider>
	);
}
