import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import { Divider, Flex, Heading, Link } from "@adobe/react-spectrum";
import { useIsMobileDevice } from "@react-spectrum/utils";

function filterFrame(frame, query) {
	const q = query.toLowerCase().trim();
	const name = frame.name.toLowerCase();

	return name.startsWith(q) || name.includes(`_${q.replace('_', '')}`);
}

function FrameIndex({ data, query }) {
	const isMobile = useIsMobileDevice();

	const sortedData = data
		.filter((frame) => filterFrame(frame, query))
		.sort((a, b) => a.name > b.name);

	const frames = [];
	let lastIndex = null;

	sortedData.forEach((frame) => {
		const firstChar = frame.name[0];

		if (firstChar !== lastIndex) {
			lastIndex = firstChar;
			frames.push({ name: firstChar, isIndex: true });
		}

		frames.push(frame);
	});

	return (
		<List
			itemCount={frames.length}
			itemData={frames}
			itemSize={isMobile ? 39 : 32}
			height={window.innerHeight - 200}
		>
			{FrameRow}
		</List>
	);
}

const FrameRow = ({ data, index, style }) => {
	const frame = data[index];

	return (
		<Flex key={frame.name} direction="column" UNSAFE_style={style}>
			{frame.isIndex ? (
				<Heading level={2} marginY="size-0">
					{frame.name}
				</Heading>
			) : (
				<Link isQuiet UNSAFE_className="index-link">
					<RouterLink to={`frame/${frame.idFrame}`}>{frame.name}</RouterLink>
				</Link>
			)}
			<Divider size="S" />
		</Flex>
	);
};

export default FrameIndex;
