import React, { useContext, useState } from "react";
import { useIsMobileDevice } from "@react-spectrum/utils";
import { ComboBox } from "@react-spectrum/combobox";
import { useAsyncList } from "@react-stately/data";

import ApiContext from "../contexts/ApiContext";

export default function RemoteComboBox(props) {
	const api = useContext(ApiContext);
	const isMobile = useIsMobileDevice();

	const [fieldState, setFieldState] = useState({
		selectedKey: null,
		isOpen: false,
		autoFocus: false,
	});

	const { children, urlFunc, getKey, ...comboProps } = props;

	const list = useAsyncList({
		getKey,

		async load({ filterText }) {
			let data = [];

			if (filterText) {
				const res = await api.get(urlFunc(filterText));
				data = res.data;
			}

			return {
				items: data,
				cursor: null,
			};
		},
	});

	const onInputChange = (value) => {
		setFieldState({
			isOpen: true,
			autoFocus: true,
			selectedKey: null,
		});

		list.setFilterText(value);
	};

	const onSelectionChange = (key) => {
		// very weird bug when combobox is in a popup
		if (key === null) return;

		setFieldState({
			isOpen: false,
			autoFocus: false,
			selectedKey: key,
		});

		if (props.onSelectionChange) {
			props.onSelectionChange(key, list.getItem(key));
		}
	};

	const onOpenChange = (isOpen) => {
		setFieldState((prevState) => ({
			isOpen,
			autoFocus: isOpen,
			selectedKey: prevState.selectedKey,
		}));
	};

	const onBlur = () => {
		setFieldState((prevState) => ({
			isOpen: false,
			autoFocus: false,
			selectedKey: prevState.selectedKey,
		}));
	};

	const isOpen =
		isMobile || list.loadingState === "idle" ? fieldState.isOpen : false;
	const loadingState = 
		!isOpen  && list.loadingState === "filtering"
			? "loading"
			: list.loadingState;

	return (
		<ComboBox
			{...comboProps}
			items={list.items}
			inputValue={list.filterText}
			selectedKey={fieldState.selectedKey}
			autoFocus={fieldState.autoFocus}
			isOpen={isOpen}
			loadingState={loadingState}
			onInputChange={onInputChange}
			onSelectionChange={onSelectionChange}
			onOpenChange={onOpenChange}
			onBlur={onBlur}
		>
			{children}
		</ComboBox>
	);
}
