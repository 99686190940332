import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export function getUtcString(dateString) {
	if (!dateString) {
		return dateString;
	}

	return dayjs
		.tz(dateString, "America/Sao_Paulo")
		.tz("UTC")
		.format("D MMM YYYY [at] H:mm ([UTC])");
}
