import React, { useState } from "react";
import useSWR from "swr";
import { useFilter } from "@react-aria/i18n";
import { Item } from "@adobe/react-spectrum";
import { ComboBox } from "@react-spectrum/combobox";

function getName(languages, key) {
	const lang = languages.find((l) => l.idLanguage === parseInt(key));
	return lang.name;
}

export default function LanguageComboBox({
	label,
	labelPosition,
	selectedKey,
	onSelectionChange,
}) {
	const { data } = useSWR("/languages", {
		dedupingInterval: 15 * 60 * 1000,
	});
	const languages = (data || []).map((language) => ({
		...language,
		name: language.description,
	}));

	const [showAll, setShowAll] = useState(false);
	const [key, setKey] = useState(null);
	const [filterValue, setFilterValue] = useState(null);

	const { startsWith } = useFilter({ sensitivity: "base" });
	const filteredItems = React.useMemo(
		() => languages.filter((item) => startsWith(item.name, filterValue || "")),
		[languages, filterValue, startsWith]
	);

	const inputValue =
		filterValue !== null
			? filterValue
			: selectedKey && data // find selected key name to set as input value
			? getName(languages, selectedKey)
			: "";
	const internalKey = selectedKey !== undefined ? selectedKey.toString() : key;

	return (
		<ComboBox
			label={label}
			labelPosition={labelPosition}
			items={showAll ? languages : filteredItems}
			inputValue={inputValue}
			selectedKey={internalKey}
			onSelectionChange={(key) => {
				if (key) {
					setFilterValue(getName(languages, key));
				}
				setKey(key);
				onSelectionChange(key);
			}}
			onInputChange={(value) => {
				setShowAll(false);
				setFilterValue(value);
			}}
			onOpenChange={(isOpen, menuTrigger) => {
				if (menuTrigger === "manual" && isOpen) {
					setShowAll(true);
				}
			}}
			width=""
			allowsCustomValue={false}
		>
			{(item) => <Item key={item.idLanguage}>{item.name}</Item>}
		</ComboBox>
	);
}
