import React, { useEffect, useState } from "react";
import { Heading, Item } from "@adobe/react-spectrum";
import { useFilter } from "@react-aria/i18n";
import { ComboBox } from "@react-spectrum/combobox";

export default function FeMapper(props) {
	const { frameA, frameB, onChange, isRequired } = props;

	// only "core" and "peripheral" must be mapped
	const fesA = frameA.fes;
	const fesB = frameB.fes;

	const filteredFesA = frameA.fes.filter((fe) => fe.type === 'core' || fe.type === 'peripheral');

	const [mapping, setMapping] = useState({});
	const [showAll, setShowAll] = useState(false);

	const { startsWith } = useFilter({ sensitivity: "base" });

	useEffect(() => {
		const feBMap = Object.fromEntries(fesB.map((feB) => [feB.name, feB]));

		setMapping(
			Object.fromEntries(
				filteredFesA.map((feA) => [
					feA.idFrameElement,
					feBMap[feA.name] || { name: feA.name, new: true },
				])
			)
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fesA, fesB]);

	useEffect(() => {
		onChange(mapping);
	}, [mapping, onChange]);

	if (filteredFesA.length === 0) {
		return null;
	}

	function onBInputChange(feA, value) {
		setShowAll(false);

		setMapping({
			...mapping,
			[feA.idFrameElement]: value ? { new: true, name: value } : null,
		});
	}

	function onBSelection(feA, key) {
		if (!key) return;

		const feB = fesB.find((feB) => feB.idFrameElement === Number(key));

		setMapping({
			...mapping,
			[feA.idFrameElement]: feB,
		});
	}

	function onOpenChange(feA, isOpen, menuTrigger) {
		if (menuTrigger === "manual" && isOpen) {
			setShowAll(true);
		}

		if (!isOpen && mapping[feA.idFrameElement]?.new) {
			const feB = fesB.find(
				(feB) =>
					feB.name.toUpperCase() ===
					mapping[feA.idFrameElement].name.toUpperCase()
			);

			// Input value matches an B FE
			if (feB) {
				setMapping({
					...mapping,
					[feA.idFrameElement]: feB,
				});
			}
		}
	}

	return (
		<>
			<Heading level="4" marginBottom="size-0">
				LINKS BETWEEN FRAME ELEMENTS
			</Heading>
			{filteredFesA.map((feA) => {
				const input = mapping[feA.idFrameElement]?.name || "";
				const key = String(mapping[feA.idFrameElement]?.idFrameElement || "");
				const items = showAll
					? fesB
					: fesB.filter((feB) => startsWith(feB.name, input));

				return (
					<ComboBox
						key={feA.idFrameElement}
						label={feA.name}
						labelPosition="side"
						items={items}
						inputValue={input}
						selectedKey={key}
						onInputChange={(value) => onBInputChange(feA, value)}
						onSelectionChange={(key) => onBSelection(feA, key)}
						onOpenChange={(isOpen, menuTrigger) =>
							onOpenChange(feA, isOpen, menuTrigger)
						}
						isRequired={isRequired}
						allowsCustomValue
						width=""
					>
						{(item) => <Item key={item.idFrameElement}>{item.name}</Item>}
					</ComboBox>
				);
			})}
		</>
	);
}
