import useSWR from "swr";

import FRAME_RELATION_TYPES from "../constants/frameRelationTypes";

const LABEL_MAP = Object.assign(
	{},
	...FRAME_RELATION_TYPES.map((x) => ({ [x.name]: x }))
);

export default function useFrameRelationTypes() {
	const { data, error } = useSWR("/relationsFrame");

	return {
		error,
		data: (data || [])
			.filter((type) => LABEL_MAP[type.name])
			.map((type) => ({
				idRelationType: type.idRelationType,
				...LABEL_MAP[type.name],
			})),
	};
}
