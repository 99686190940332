import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FrameNameHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>How to name a frame?</Heading>
			<Divider />
			<Content>
				<Text>
					<p>
						The level of generality of a frame name must correlate with the
						diversity of Lexical Units in it. Ex.: The{" "}
						<span className="frame-name">Finish_competition</span> frame can be
						evoked by tie.v, win.v and loose.v. Note, therefore, that the frame
						name does not specify the alternative scenarios in which a
						competition may end.
					</p>
					<p>
						The name must also be specific enough to distinguish the frame from
						others. Ex.: <span className="frame-name">People_by_origin</span>{" "}
						and <span className="frame-name">People_by_vocation</span> are
						subtypes of the <span className="frame-name">People</span> frame.
					</p>
					<p>
						In general, the frame name follows patterns related to the type of
						frame being created.
					</p>
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() => window.open("https://youtu.be/828-Tgs_TTQ", "_blank")}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FrameNameHelpDialog;
