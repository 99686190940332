import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	AlertDialog,
	Button,
	ButtonGroup,
	Checkbox,
	Content,
	DialogTrigger,
	Flex,
	Link as SpectrumLink,
	TextField,
} from "@adobe/react-spectrum";

import ActionHeader from "../components/ActionHeader";
import LanguageComboBox from "../components/LanguageComboBox";
import ApiContext from "../contexts/ApiContext";

function RegisterScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();

	const [affiliationText, setAffiliationText] = useState("");
	const [highestDegreeText, setHighestDegreeText] = useState("");
	const [degreeGrantedByText, setDegreeGrantedByText] = useState("");
	const [hasAgreedWithTerms, setHasAgreedWithTerms] = useState(false);
	const [languageId, setlanguageId] = useState();

	async function submit() {
		await api.patch(`/user`, {
			affiliation: affiliationText,
			highestDegree: highestDegreeText,
			degreeGrantedBy: degreeGrantedByText,
			hasAgreedWithTerms: hasAgreedWithTerms,
			languages: [{ idLanguage: languageId }],
		});

		history.push("/my-frames");
	}

	return (
		<Flex flex={1} direction="column" gap="size-150" margin="size-200">
			<ActionHeader
				title="Register Account"
				align="center"
				isCancelable={false}
			/>

			<TextField
				value={affiliationText}
				onChange={setAffiliationText}
				label="Affiliation"
				isRequired
				width=""
			/>

			<TextField
				value={highestDegreeText}
				onChange={setHighestDegreeText}
				label="Highest educational degree"
				isRequired
				width=""
			/>

			<TextField
				value={degreeGrantedByText}
				onChange={setDegreeGrantedByText}
				label="Granted by"
				isRequired
				width=""
			/>

			<LanguageComboBox
				label="Primary language"
				onSelectionChange={setlanguageId}
			/>

			<Checkbox
				isSelected={hasAgreedWithTerms}
				onChange={setHasAgreedWithTerms}
			>
				{/* I agree to the terms and conditions and the privacy policy. */}
				Lutma's database is licensed under GPLv3. By ticking this box you accept that all data created or modified by you will be under the same license.
			</Checkbox>

			<SpectrumLink marginStart="size-300">
					<a href={`https://www.gnu.org/licenses/gpl-3.0.html`} target="_blank">See full GPLv3 text</a>
				</SpectrumLink>

			<DialogTrigger>
				<ButtonGroup align="center">
					<Button
						variant="cta"
						marginTop="size-150"
						isDisabled={
							!(
								affiliationText &&
								highestDegreeText &&
								degreeGrantedByText &&
								hasAgreedWithTerms
							)
						}
					>
						Save
					</Button>
				</ButtonGroup>
				<AlertDialog
					title="Warning: Lutma's testing phase"
					variant="warning"
					primaryActionLabel="Continue"
					onPrimaryAction={submit}
				>
					<Content>
					Lutma is a tool developed to facilitate the creation of frames in a connected and multilingual database. Since it is still under development, we may update its UI/UX, change the frame creation process, add new functionalities or remodel aspects of its database. Some of those changes may not be backward-compatible, <span className="yellow-text">by using the system in its test phase, you acknowledge that any data that you create or modify can be later modified or deleted.</span>
					</Content>
					
				</AlertDialog>
			</DialogTrigger>
		</Flex>
	);
}

export default RegisterScreen;
