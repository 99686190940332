import React, { useState, useContext } from "react";
import useSWR, { mutate } from "swr";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Flex,
	Heading,
	Item,
	Picker,
} from "@adobe/react-spectrum";

import ActionHeader from "../components/ActionHeader";
import FeRelationFieldLabel from "../components/tutorials/fe/FeRelationFieldLabel";
import ApiContext from "../contexts/ApiContext";

import FE_RELATION_TYPES from "../constants/frameElementRelationTypes";

const FE_RELATION_MAP = Object.assign(
	{},
	...FE_RELATION_TYPES.map((x) => ({ [x.id]: x.name }))
);

function CreateFeRelationDialog({ frame, relations, close }) {
	const api = useContext(ApiContext);

	// only core FEs can be in a relationship.
	const fes = frame.fes.filter(
		(fe) =>
			fe.isSelected && (fe.type === "core" || fe.type === "core-unexpressed")
	);

	const { data: relationTypes } = useSWR("/relationsFrameElement");

	const [relationType, setRelationType] = useState(null);
	const [feId1, setFeId1] = useState(null);
	const [feId2, setFeId2] = useState(null);

	async function onSave() {
		await api.post(`/frames/${frame.idFrame}/ferelations`, {
			idFrame: frame.idFrame,
			idFrameElement1: feId1,
			idFrameElement2: feId2,
			idRelationType: relationType,
			isActive: true,
		});

		mutate(`/frames/${frame.idFrame}/ferelations`);

		close();
	}

	const isInvalidRelation = feId1 && feId2 && feId1 === feId2;
	const isExistingRelation = (relations || []).find(
		(relation) =>
			relation.idFrameElement1 === feId1 && relation.idFrameElement2 === feId2
	);
	const isSelectionInvalid = isInvalidRelation || isExistingRelation;

	return (
		<Dialog mobileType="fullscreen">
			<Heading>
				<ActionHeader
					title={frame.name}
					description="You are adding a new FE-to-FE relation to"
					isCancelable={false}
				/>
			</Heading>
			<Divider />
			<Content>
				<Flex direction="column" gap="size-150">
					<Picker
						items={relationTypes || []}
						isLoading={!relationTypes}
						selectedKey={relationType}
						onSelectionChange={setRelationType}
						label={<FeRelationFieldLabel />}
						width=""
					>
						{(item) => (
							<Item key={item.idRelationType}>
								{FE_RELATION_MAP[item.name]}
							</Item>
						)}
					</Picker>

					<Picker
						label="between"
						labelPosition="side"
						items={fes || []}
						selectedKey={feId1}
						onSelectionChange={setFeId1}
						validationState={isSelectionInvalid ? "invalid" : "valid"}
					>
						{(item) => <Item key={`${item.idFrameElement}`}>{item.name}</Item>}
					</Picker>

					<Picker
						label="and"
						labelPosition="side"
						items={fes || []}
						selectedKey={feId2}
						onSelectionChange={setFeId2}
						validationState={isSelectionInvalid ? "invalid" : "valid"}
					>
						{(item) => <Item key={`${item.idFrameElement}`}>{item.name}</Item>}
					</Picker>
					{isInvalidRelation && (
						<span className="error-text">
							An FE cannot be in a relation with itself.
						</span>
					)}
					{isExistingRelation && (
						<span className="error-text">
							The selected FEs are already in a relationship.
						</span>
					)}
				</Flex>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={close}>
					Close
				</Button>
				<Button
					variant="cta"
					onPress={onSave}
					isDisabled={isSelectionInvalid || !relationType || !feId1 || !feId2}
				>
					Save
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default CreateFeRelationDialog;
