import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FrameScenarioHelpDialog from './FrameScenarioHelpDialog'

function FrameScenarioFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<Help />
			</ActionButton>
			{close => <FrameScenarioHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FrameScenarioFieldLabel;