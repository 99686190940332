import React from 'react';
import {
	Dialog,
	Divider,
	Content,
	Heading,
	Text,
} from '@adobe/react-spectrum';

function LuPreviewCoachDialog({ onDismiss }) {
	return (
		<Dialog onDismiss={onDismiss}>
			<Heading>Quick preview</Heading>
			<Divider />
			<Content>
				<Text>Click on an LU for a preview of the frame description</Text>
			</Content>
		</Dialog>
	)
}

export default LuPreviewCoachDialog;