import React, { useContext, useState } from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Flex,
	Heading,
	Item,
	TextArea,
	Picker,
} from "@adobe/react-spectrum";
import { useHistory } from "react-router-dom";

import ActionHeader from "../components/ActionHeader";
import ApiContext from "../contexts/ApiContext";

import LuDefinitionFieldLabel from "../components/tutorials/lu/LuDefinitionFieldLabel";
import LuExampleSentenceFieldLabel from "../components/tutorials/lu/LuExampleSentenceFieldLabel";
import LuIncorporatedFeFieldLabel from "../components/tutorials/lu/LuIncorporatedFeFieldLabel";

export default function FinishLUCreationDialog({ frame, onClose }) {
	const history = useHistory();
	const api = useContext(ApiContext);

	const [feId, setFeId] = useState(null);
	const [exampleText, setExampleText] = useState("");
	const [definitionText, setDefinitionText] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const lu = frame?.lus[0];

	async function onSave() {
		setIsLoading(true);

		await api.patch(`frames/${frame.idFrame}/${lu.idLU}`, {
			description: definitionText,
			exampleText: exampleText,
			isDraft: Number(false),
			idFrameElement: feId,
		});

		await api.patch(`frames/${frame.idFrame}`, {
			isDraft: Number(false),
		});

		setIsLoading(false);
		onClose();
		history.push("/my-frames");
	}

	return (
		<Dialog mobileType="fullscreen">
			<Heading>
				<ActionHeader
					title={lu?.name}
					description={frame ? `${frame.name} will be evoked by` : "Loading..."}
					isCancelable={false}
				/>
			</Heading>
			<Divider />
			<Content>
				<Flex direction="column" gap="size-150">
					<TextArea
						label={<LuDefinitionFieldLabel />}
						value={definitionText}
						onChange={setDefinitionText}
						isRequired
						necessityIndicator=""
						width=""
					/>

					<Picker
						label={<LuIncorporatedFeFieldLabel />}
						selectedKey={feId}
						onSelectionChange={setFeId}
						validationState="valid"
						items={
							frame
								? frame.fes.filter((fe) => fe.type === "core" && fe.isSelected)
								: []
						}
						isLoading={!frame}
						width=""
					>
						{(item) => <Item key={item.idFrameElement}>{item.name}</Item>}
					</Picker>

					<TextArea
						label={<LuExampleSentenceFieldLabel />}
						value={exampleText}
						onChange={setExampleText}
						isRequired
						necessityIndicator=""
						width=""
					/>
				</Flex>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={onClose} isDisabled={isLoading}>
					Close
				</Button>
				<Button variant="cta" onPress={onSave} isDisabled={isLoading}>
					Save
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}
