import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FrameRelationHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>What is a frame-to-frame relation?</Heading>
			<Divider />
			<Content>
				<Text>
					FrameNet is a network of frames, meaning that frames are linked to one
					another via typed relations. Relation types include inheritance,
					subframe and perspective on, among others. For example, the{" "}
					<span className="frame-name">Travel</span> frame inherits from the{" "}
					<span className="frame-name">Self_motion</span> frame, since the first
					is a specific type of the latter.
				</Text>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={() => window.open("https://youtu.be/NqsDHM2F0hU", "_blank")}>Go to tutorial</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FrameRelationHelpDialog;
