import React, { useState } from "react";
import { ActionButton, DialogTrigger, Flex, View } from "@adobe/react-spectrum";

import ShowMenu from "@spectrum-icons/workflow/ShowMenu";

import NavigationDialog from "../dialogs/NavigationDialog";

const css = `
	div[class*="_spectrum-Tray_"] {
		left: 0;
		transform: translateX(-50%) !important;
		max-height: var(--spectrum-visual-viewport-height);
		max-width: var(--spectrum-global-dimension-static-size-3600);
		height: var(--spectrum-visual-viewport-height);
	}

	div[class*="_spectrum-Tray_"][class*="_is-open"] {
		transform: translateX(0%) !important;
		color: pimba;
	}
`;
const head = document.head || document.getElementsByTagName("head")[0];
const styleTag = document.createElement("style");
styleTag.appendChild(document.createTextNode(css));

function AppBar(props) {
	const { children } = props;

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [timeoutId, setTimeoutId] = useState();

	function onOpenMenu(isOpen) {
		if (!isOpen) {
			setTimeoutId(setTimeout(() => head.removeChild(styleTag), 250));
		}
		setIsMenuOpen(isOpen);
	}

	function onPressShowMenu() {
		head.appendChild(styleTag);
		clearTimeout(timeoutId);
	}

	return (
		<Flex flex={1} direction="column">
			<Flex position="fixed" width="100%" zIndex={4}>
				<View backgroundColor="default" width="size-4600">
					<Flex
						marginStart="size-100"
						marginTop="size-200"
						marginEnd="size-200"
						marginBottom="size-100"
					>
						<DialogTrigger
							isOpen={isMenuOpen}
							onOpenChange={onOpenMenu}
							type="tray"
						>
							<ActionButton isQuiet onPressStart={onPressShowMenu}>
								<ShowMenu size="M" />
							</ActionButton>
							{(close) => <NavigationDialog close={close} />}
						</DialogTrigger>
						<Flex flex={1} justifyContent="end" gap="size-100">
							{children}
						</Flex>
					</Flex>
				</View>
			</Flex>
			<Flex flex={1} marginTop="size-600"></Flex>
		</Flex>
	);
}

export default AppBar;
