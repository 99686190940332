import React from 'react';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from '@adobe/react-spectrum';
import { useHistory } from 'react-router-dom';


function CancelConfirmationDialog({ close } ) {
	const history = useHistory();

	return (
		<Dialog>
			<Heading>Everything not saved will be lost</Heading>
			<Divider />
			<Content>
				<Text>If you cancel before saving, you will lose all you've done so far.</Text>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={close}>Go back</Button>
				<Button variant="cta" onPress={() => history.push('/my-frames')}>Cancel</Button>
			</ButtonGroup>
		</Dialog>
	)
}

export default CancelConfirmationDialog;