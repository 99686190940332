import React from "react";
import {
	Flex,
	Heading,
	Link,
	Text,
} from "@adobe/react-spectrum";

import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";

export default function AboutScreen() {
	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" gap="size-115" margin="size-200">
				<PageHeading>What is Lutma?</PageHeading>

				<Text>Lutma is a frame maker tool aimed at enhancing <Link><a href="https://www.globalframenet.org">Global FrameNet</a></Link> coverage via a collaborative effort by both framenet experts and the general public. The tool is built so as to guide the user, regardless of their level of expertise, through the process of creating a new frame or suggesting a new lexical unit for an already existing frame. The tool is web-based and runs in any browser.</Text>

				<Heading>Who built Lutma?</Heading>

				<Text>Lutma was developed by the <Link><a href="https://www.ufjf.br/framenetbr-eng/">FrameNet Brasil</a></Link> team at the Federal University of Juiz de Fora, in collaboration with the <Link><a href="https://www.redhenlab.org/home">International Distributed Little Red Hen Lab</a></Link> and the <Link><a href="https://www.globalframenet.org/partners">Global FrameNet partners</a></Link>.</Text>

				<Heading>Who's who in the Lutma dev team?</Heading>

				<ul style={{ "margin": 0 }}>
					<li>Tiago Timponi Torrent, general coordinator</li>
					<li>Ely Edison da Silva Matos, chief developer</li>
					<li>Maucha Andrade Gamonal, frame creation expert</li>
					<li>Frederico Belcavello, video expert</li>
					<li>Marcelo Viridiano, UX designer</li>
					<li>Arthur Lorenzi Almeida, front-end developer</li>
				</ul>

				<Heading>Who funded Lutma?</Heading>

				<Text>
				The project was funded in part by an Alexander von Humboldt Anneliese Maier Research Award made to Mark Turner (2015-2021).

				</Text>
			</Flex>
		</Flex>
	);
}
