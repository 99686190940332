import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import FeDefinitionHelpDialog from './FeDefinitionHelpDialog'

function FeDefinitionFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>Frame element definition</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <FeDefinitionHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FeDefinitionFieldLabel;