import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FeNonCoreHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>What are non-core FEs?</Heading>
			<Divider />
			<Content>
				<Text>
					Non-core frame elements indicate circumstantial information that can
					be attached to the frame. Depending on the frame root type, different
					sets of non-core frame elements tend to occur.
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() =>
						window.open("https://www.youtube.com/watch?v=hhAgJPt8oIU", "_blank")
					}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FeNonCoreHelpDialog;
