import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Add from "@spectrum-icons/workflow/Add";
import { useHistory, useParams } from "react-router-dom";
import {
	ActionButton,
	Button,
	ButtonGroup,
	Checkbox,
	CheckboxGroup,
	DialogTrigger,
	Flex,
	Text,
} from "@adobe/react-spectrum";

import Cancel from "@spectrum-icons/workflow/Cancel";

import AppBar from "../components/AppBar";
import PageHeading from "../components/PageHeading";
import FrameRelationScreenHeading from "../components/tutorials/frame/FrameRelationScreenHeading";
import CreateFrameToFrameRelationDialog from "../dialogs/CreateFrameToFrameRelationDialog";
import ApiContext from "../contexts/ApiContext";
import { getRelationLabels } from "../utils/frameRelations";

import CancelConfirmationDialog from "../dialogs/CancelConfirmationDialog";

function FrameToFrameRelationScreen() {
	const api = useContext(ApiContext);

	const history = useHistory();
	const { id: frameId } = useParams();

	const { data: frame } = useSWR(`/frames/${frameId}?_embed=fes`);
	const { data: relations, mutate } = useSWR(
		`/framerelations?idFrame=${frameId}&_expand=frames`
	);

	const [isLoading, setIsLoading] = useState(false);
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		if (relations && !selected) {
			setSelected(
				relations
					.filter((relation) => Boolean(Number(relation.isActive)))
					.map((relation) => relation.idEntityRelation)
			);
		}
	}, [relations, selected, setSelected]);

	function onPressSkip() {
		history.push(`/frame/${frameId}/frame-elements`);
	}

	async function onPressSave() {
		const selectedSet = new Set(selected);

		const updated = relations.filter((relation) => {
			const isActive = Boolean(Number(relation.isActive));
			const isSelected = selectedSet.has(relation.idEntityRelation);

			return isActive - isSelected !== 0;
		});

		setIsLoading(true);
		await Promise.all(
			updated.map((relation) =>
				api.patch(`/framerelations/${relation.idEntityRelation}`, {
					isActive: Number(selectedSet.has(relation.idEntityRelation)),
				})
			)
		);
		await mutate();
		setIsLoading(false);

		history.push(`/frame/${frameId}/frame-elements`);
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<DialogTrigger>
					<ActionButton>
						<Cancel size="S" />
					</ActionButton>
					{(close) => <CancelConfirmationDialog close={close} />}
				</DialogTrigger>
			</AppBar>

			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				<PageHeading
					subtitle={frame ? `${frame.name} has the following` : "Loading..."}
				>
					<FrameRelationScreenHeading />
				</PageHeading>

				<CheckboxGroup
					value={selected || []}
					onChange={setSelected}
					aria-label="Frame to frame relations"
				>
					{frame &&
						(relations || []).map((relation) => {
							const labels = getRelationLabels(relation, frame.idFrame);

							return (
								<Checkbox
									key={relation.idEntityRelation}
									value={relation.idEntityRelation}
								>
									<span className="bold-text autocapitalize-text">
										{labels.relation}
									</span>{" "}
									<span>{labels.name}</span>.
								</Checkbox>
							);
						})}
				</CheckboxGroup>

				<Flex marginY="size-150">
					<DialogTrigger>
						<ActionButton
							variant="secondary"
							alignSelf="start"
							isDisabled={!frame}
						>
							<Add />
							<Text>Add Frame-to-Frame relation</Text>
						</ActionButton>
						{(close) => (
							<CreateFrameToFrameRelationDialog close={close} frame={frame} />
						)}
					</DialogTrigger>
				</Flex>

				<ButtonGroup align="center" orientation="vertical">
					<Button
						variant="cta"
						onPress={onPressSave}
						isDisabled={isLoading || !relations || relations.length === 0}
					>
						SAVE RELATIONS
					</Button>
					<Button variant="secondary" onPress={onPressSkip}>
						SKIP THIS STEP
					</Button>
				</ButtonGroup>
			</Flex>
		</Flex>
	);
}

export default FrameToFrameRelationScreen;
