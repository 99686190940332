import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from './FieldLabelText'
import GenericHelpDialog from '../dialogs/GenericHelpDialog'

function FieldLabelWithHelp({ label, dialogTitle, dialogText }) {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>{label}</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <GenericHelpDialog title={dialogTitle} text={dialogText} close={close} />}
		</DialogTrigger>
	);
}

export default FieldLabelWithHelp;