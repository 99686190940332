import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import FrameRootHelpDialog from './FrameRootHelpDialog'

function FrameDefinitionFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>Select root frame type</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <FrameRootHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FrameDefinitionFieldLabel;