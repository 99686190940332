import React, { useState, useEffect, useRef } from "react";
import {
	Button,
	ButtonGroup,
	Flex,
	Heading,
	SearchField,
} from "@adobe/react-spectrum";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";

import useQuery from "../hooks/useQuery";
import useLuSearch from "../hooks/useLuSearch";
import AppBar from "../components/AppBar";
import LuQueryFieldLabel from "../components/tutorials/lu/LuQueryFieldLabel";
import LuSearchResultList from "../components/LuSearchResultList";
import { setAutocapitalize } from "../utils/dom";

const resultTitle = {
	match: "IT'S A MATCH!",
	synonyms: "LEMMA SYNONYM IN OTHER FRAMES",
	global: "LEMMA IN OTHER LANGUAGES",
};

function LuSearchResultScreen() {
	const history = useHistory();
	const query = useQuery();
	const isSynonym = useRouteMatch("/search/results/synonyms");
	const isGlobal = useRouteMatch("/search/results/global");
	const type = isGlobal ? "global" : isSynonym ? "synonyms" : "match";

	const { q, lang, pos, finished: isFinished } = query;

	const textRef = useRef();
	const [queryText, setQueryText] = useState(q);

	let { data } = useLuSearch(type, query);

	if (isFinished) data = [];
	if (type === "global")
		data = ([] || data).filter((lu) => lu.idLanguage !== lang);

	useEffect(() => setAutocapitalize(textRef), []);

	let nextPath;
	const isLoading = !data;
	const hasResults = data && data.length > 0;

	if (type === "match") {
		nextPath = `/search/results/synonyms?q=${q}&lang=${lang}&pos=${pos}`;
	} else if (type === "synonyms") {
		nextPath = `/search/results/global?q=${q}&lang=${lang}&pos=${pos}`;
	} else {
		nextPath = `/search/results/global?q=${q}&lang=${lang}&pos=${pos}&finished=true`;
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" gap="size-150" margin="size-200">
				{data && data.length === 0 && nextPath && <Redirect to={nextPath} />}

				<Heading level={3} margin="size-0">
					Search Lexical Unit
				</Heading>

				<SearchField
					ref={textRef}
					label={<LuQueryFieldLabel />}
					value={queryText}
					onChange={setQueryText}
					onSubmit={() =>
						history.push(
							`/search/results?q=${queryText}&lang=${lang}&pos=${pos}`
						)
					}
					width=""
				/>

				<LuSearchResultList
					data={data}
					title={resultTitle[type]}
					type={type}
					isHidden={isFinished}
				/>

				<ButtonGroup
					orientation="vertical"
					align="center"
					marginTop="size-150"
					isHidden={isLoading}
				>
					<Button
						variant="cta"
						onPress={() => history.push(`/search/`)}
						isHidden={type !== "match"}
					>
						YEP! THOSE ARE THE FRAMES
					</Button>

					<Button
						variant="cta"
						onPress={() => history.push(nextPath)}
						isHidden={isFinished || !hasResults}
					>
						NOT THE FRAMES
					</Button>

					<Button
						variant="cta"
						onPress={() =>
							history.push(
								`/create/frame-root?lemma=${q}&lang=${lang}&pos=${pos}`
							)
						}
						isHidden={!isFinished && hasResults}
					>
						CREATE A NEW FRAME
					</Button>

					<Button
						variant="secondary"
						onPress={() => history.push("/help")}
						isHidden={!isFinished && hasResults}
					>
						REPORT A BUG
					</Button>
				</ButtonGroup>
			</Flex>
		</Flex>
	);
}

export default LuSearchResultScreen;
