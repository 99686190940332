import React, { useContext, useState } from "react";
import useSWR from "swr";
import { v4 } from "uuid";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
	ActionButton,
	ButtonGroup,
	Button,
	DialogContainer,
	DialogTrigger,
	Flex,
	Item,
	Menu,
	MenuTrigger,
	TextArea,
	AlertDialog,
} from "@adobe/react-spectrum";
import { useParams } from "react-router-dom";

import Alert from "@spectrum-icons/workflow/Alert";
import Cancel from "@spectrum-icons/workflow/Cancel";
import Delete from "@spectrum-icons/workflow/Delete";
import Edit from "@spectrum-icons/workflow/Edit";

import AppBar from "../components/AppBar";
import FeViewer from "../components/FeViewer";
import PageHeading from "../components/PageHeading";
import FrameRelationViewer from "../components/FrameRelationViewer";
import FinishLUCreationDialog from "../dialogs/FinishLUCreationDialog";
import ApiContext from "../contexts/ApiContext";
import useFeRelations from "../hooks/useFeRelations";

import CancelConfirmationDialog from "../dialogs/CancelConfirmationDialog";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog";

import "@spectrum-web-components/accordion/sp-accordion.js";
import "@spectrum-web-components/accordion/sp-accordion-item.js";

function FrameSummaryScreen() {
	const api = useContext(ApiContext);

	const location = useLocation();
	const history = useHistory();
	const isSummary = useRouteMatch("/frame/:id/summary");

	const [isPublishing, setIsPublishing] = useState(false);
	const [shouldFinishLu, setShouldFinishLu] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const { id: frameId } = useParams();
	const { data: frame, mutate } = useSWR(`/frames/${frameId}?_embed=fes,lus`);
	const { data: frameRelations } = useSWR(
		`/framerelations?idFrame=${frameId}&_expand=frames`
	);
	const { data: feRelations } = useFeRelations(frameId);

	const isIncomplete = frame?.fes && frame.fes.length === 0;
	const description = frame ? frame.description : "";

	async function onPressAdd() {
		setIsPublishing(true);
		// TODO: ideally, this should be checked on the backend
		const { data } = await api.get(`/frames?name=${frame.name}`);

		if (data.length > 0) {
			setIsPublishing(false);
			setErrorMessage(
				"The frame could not be saved because it already exists in the database."
			);
			return;
		}

		const lus = frame.lus || [];

		if (lus.length === 1 && !lus[0].description) {
			setShouldFinishLu(true);
			setIsPublishing(false);
			return;
		}

		await api.patch(`frames/${frame.idFrame}`, {
			isDraft: Number(false),
		});
		setIsPublishing(false);

		history.push("/my-frames");
	}

	function onActionEdit(key) {
		// TODO: maybe specify a returnTo param
		const splits = location.pathname.split("/");

		splits.pop();
		splits.push(key);
		history.push(splits.join("/"));
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar>
				<MenuTrigger>
					<ActionButton>
						<Edit size="S" />
					</ActionButton>

					<Menu onAction={onActionEdit}>
						<Item key="frame-elements">Frame Elements</Item>
						<Item key="frame-relations">Frame-to-Frame relations</Item>
						<Item key="fe-relations">Frame Element relations</Item>
					</Menu>
				</MenuTrigger>

				{isSummary ? (
					<DialogTrigger>
						<ActionButton>
							<Cancel size="S" />
						</ActionButton>
						{(close) => <CancelConfirmationDialog close={close} />}
					</DialogTrigger>
				) : (
					<DialogTrigger>
						<ActionButton>
							<Delete size="S" color="negative" />
						</ActionButton>
						{(close) => <DeleteConfirmationDialog close={close} />}
					</DialogTrigger>
				)}
			</AppBar>
			<Flex
				flex={1}
				direction="column"
				gap="size-150"
				margin="size-200"
				UNSAFE_className="sp-accordion"
			>
				<PageHeading
					title={frame ? frame.name : "Loading..."}
					subtitle={"You are about to save"}
				/>

				<sp-accordion allow-multiple>
					<sp-accordion-item label="Definition" open>
						<Flex flex={1}>
							<TextArea
								aria-label="Frame definition"
								value={description}
								onChange={(v) => mutate({ ...frame, description: v }, false)}
								width="100%"
								minHeight="size-1600"
							/>
						</Flex>
					</sp-accordion-item>

					<sp-accordion-item label="Frame elements" open>
						{isIncomplete ? (
							<Flex UNSAFE_className="error-text">
								<Alert aria-label="Negative Alert" color="negative" size="S" />
								<span>
									This frame has no Core FEs and at least one is required.{" "}
								</span>
							</Flex>
						) : (
							<FeViewer fes={frame?.fes} />
						)}
					</sp-accordion-item>

					<sp-accordion-item label="Fe-to-fe relations" open>
						{feRelations
							.filter((relation) => !relation.isInvalid)
							.map((relation) => (
								<div key={v4()}>
									- There is a FE-to-FE relation of{" "}
									<span className="bold-text">{relation.type}</span> between{" "}
									<span className="bold-text">{`${relation.fe1Name} (${relation.fe1Type})`}</span>{" "}
									and{" "}
									<span className="bold-text">{`${relation.fe2Name} (${relation.fe2Type})`}</span>
									.
								</div>
							))}
					</sp-accordion-item>

					<sp-accordion-item label="Frame relations" open>
						<FrameRelationViewer relations={frameRelations} frame={frame} />
					</sp-accordion-item>
				</sp-accordion>

				<ButtonGroup align="center" orientation="vertical" marginTop="size-150">
					<Button
						variant="cta"
						onPress={onPressAdd}
						isDisabled={!frame || isPublishing || isIncomplete}
					>
						ADD FRAME
					</Button>
				</ButtonGroup>

				<DialogContainer onDismiss={() => setErrorMessage(null)}>
					{errorMessage && (
						<AlertDialog
							title="Error"
							variant="error"
							primaryActionLabel="Cancel"
						>
							{errorMessage}
						</AlertDialog>
					)}
				</DialogContainer>

				<DialogContainer onDismiss={() => setShouldFinishLu(false)}>
					{shouldFinishLu && (
						<FinishLUCreationDialog
							frame={frame}
							onClose={() => setShouldFinishLu(false)}
						/>
					)}
				</DialogContainer>
			</Flex>
		</Flex>
	);
}

export default FrameSummaryScreen;
