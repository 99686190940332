import React from "react";
import { useIsMobileDevice } from "@react-spectrum/utils";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";
import { Link as RouterLink } from "react-router-dom";
import { Divider, Flex, Link, ProgressCircle } from "@adobe/react-spectrum";

function LuIndex({ data, hasNextPage, isNextPageLoading, loadNextPage }) {
	const isMobile = useIsMobileDevice();

	const items = data.flat();
	const itemCount = hasNextPage ? items.length + 1 : items.length;
	const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

	const isItemLoaded = (index) => !hasNextPage || index < items.length;

	const LuRow = ({ data, index, style }) => {
		const lu = data[index];

		if (!isItemLoaded(index)) {
			return (
				<Flex
					key={"loading-flex"}
					UNSAFE_style={style}
					justifyContent="center"
					alignItems="middle"
					marginY="size-100"
				>
					<ProgressCircle isIndeterminate aria-label="loading" />
				</Flex>
			);
		}

		return (
			<Flex key={lu.luFrame} direction="column" UNSAFE_style={style}>
				<Link isQuiet UNSAFE_className="index-link">
					<RouterLink to="">{lu.luFrame}</RouterLink>
				</Link>
				<Divider size="S" />
			</Flex>
		);
	};

	return (
		<InfiniteLoader
			isItemLoaded={isItemLoaded}
			itemCount={itemCount}
			loadMoreItems={loadMoreItems}
		>
			{({ onItemsRendered, ref }) => (
				<List
					itemCount={itemCount}
					itemData={items}
					itemSize={isMobile ? 39 : 32}
					height={window.innerHeight - 200}
					onItemsRendered={onItemsRendered}
					ref={ref}
				>
					{LuRow}
				</List>
			)}
		</InfiniteLoader>
	);
}

export default LuIndex;
