import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import FrameNameHelpDialog from './FrameNameHelpDialog'

function FrameNameFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>Enter frame name</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <FrameNameHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FrameNameFieldLabel;