import React from 'react';
import {
	Dialog,
	Divider,
	Content,
	Heading,
	Text,
} from '@adobe/react-spectrum';

function AddLuToFrameCoachDialog({ onDismiss }) {
	return (
		<Dialog onDismiss={onDismiss}>
			<Heading>Add LU to this frame</Heading>
			<Divider />
			<Content>
				<Text>Click the '+' icon to add a new LU to this specific frame</Text>
			</Content>
		</Dialog>
	)
}

export default AddLuToFrameCoachDialog;