import React from "react";
import { Flex, Link, Text } from "@adobe/react-spectrum";

import PageHeading from "../components/PageHeading";

import AppBar from "../components/AppBar";

export default function HelpScreen() {
	return (
		<Flex flex={1} direction="column">
			<AppBar />
			<Flex flex={1} direction="column" gap="size-115" margin="size-200">
				<PageHeading>How Can We Help?</PageHeading>

				<Text marginTop="size-150">
					If you've encountered a bug using Lutma or want to ask questions,
					contact us at{" "}
					<Link>
						<a href="mailto:projeto.framenetbr@ufjf.edu.br">
							projeto.framenetbr@ufjf.edu.br
						</a>
					</Link>
					.
				</Text>

				<Text>
					Any form of feedback is welcome, feel free to comment on the
					interface, usability, tutorials or anything that you think is
					important.
				</Text>
			</Flex>
		</Flex>
	);
}
