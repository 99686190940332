import React from "react";
import Help from "@spectrum-icons/ui/HelpSmall";
import { ActionButton, DialogTrigger, Heading } from "@adobe/react-spectrum";
import { useIsMobileDevice } from "@react-spectrum/utils";

import FrameRelationHelpDialog from "./FrameRelationHelpDialog";

function FrameRelationScreenHeading() {
	const isMobile = useIsMobileDevice();

	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<Heading level={isMobile ? 3 : 2} margin="size-0">
					Frame-to-Frame relations
				</Heading>
				<Help size="S" />
			</ActionButton>
			{(close) => <FrameRelationHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FrameRelationScreenHeading;
