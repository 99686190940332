import React from 'react';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from '@adobe/react-spectrum';

function FrameScenarioHelpDialog({ close } ) {
	return (
		<Dialog>
			<Heading>Scenario frames</Heading>
			<Divider />
			<Content>
				<Text>A scenario frame represents a domain of knowledge, meaning that there will be a set of other frames related to it in a network representing the concepts in the domain.</Text>
			</Content>
			<ButtonGroup>
				<Button variant="cta" onPress={close}>Got it!</Button>
			</ButtonGroup>
		</Dialog>
	)
}

export default FrameScenarioHelpDialog;