import React from 'react';
import Help from '@spectrum-icons/ui/HelpSmall';
import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import FieldLabelText from '../../FieldLabelText'
import FeNameHelpDialog from './FeNameHelpDialog'

function FeNameFieldLabel() {
	return (
		<DialogTrigger>
			<ActionButton isQuiet>
				<FieldLabelText>Add the Frame Element</FieldLabelText>
				<Help />
			</ActionButton>
			{close => <FeNameHelpDialog close={close} />}
		</DialogTrigger>
	);
}

export default FeNameFieldLabel;