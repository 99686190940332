import React, { useContext } from "react";
import useSWR from 'swr';
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";
import { useHistory } from "react-router-dom";

import ApiContext from "../contexts/ApiContext";

function AddLanguageConfirmationDialog({ frame, newLanguage, close }) {
	const api = useContext(ApiContext);

	const history = useHistory();

	const { data: allLanguages } = useSWR("/languages", {
		dedupingInterval: 15 * 60 * 1000,
	});

	const frameLanguagesSet = new Set(
		frame.languages.map((language) => language.idLanguage)
	);
	let languages = allLanguages
		? allLanguages
				.filter((language) => frameLanguagesSet.has(language.idLanguage))
				.map((language) => language.description.toUpperCase())
		: [];

	if (languages.length >= 2) {
		let last = languages[languages.length - 1];
		languages = languages.slice(0, -1);
		languages[languages.length - 1] += ` and ${last}`;
	}

	async function onPressAdd() {
		await api.post(`/frames/${frame.idFrame}/languages`, {
			idFrame: frame.idFrame,
			idLanguage: newLanguage.idLanguage,
		});

		history.push(`/my-frames`);
	}

	return (
		<Dialog>
			<Heading>Oops! I found a similar frame</Heading>
			<Divider />
			<Content>
				<Text>
					I found <span className="bold">{frame.name}</span> in{" "}
					<Text>{languages.join(", ")}</Text>
					{`. Would you like to add ${newLanguage.description.toUpperCase()} to this frame?`}
				</Text>
			</Content>
			<ButtonGroup>
				<Button variant="secondary" onPress={close}>
					Go back
				</Button>
				<Button variant="cta" onPress={onPressAdd}>
					Add
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default AddLanguageConfirmationDialog;
