import React from "react";
import { SWRConfig } from "swr";
import {
	Provider as SpectrumProvider,
	defaultTheme,
} from "@adobe/react-spectrum";
import { Auth0Provider } from "@auth0/auth0-react";

import ApiContext, { ApiContextProvider } from "./contexts/ApiContext";
import { UiStateContextProvider } from "./contexts/UiStateContext";

import history from "./utils/history";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

function Providers({ children }) {
	const onRedirectCallback = (appState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			cacheLocation="localstorage"
		>
			<UiStateContextProvider>
				<ApiContextProvider>
					<ApiContext.Consumer>
						{(api) => (
							<SWRConfig
								value={{
									fetcher: (url) => api.get(url).then((res) => res.data),
								}}
							>
								<SpectrumProvider
									theme={defaultTheme}
									defaultColorScheme="light"
								>
									{children}
								</SpectrumProvider>
							</SWRConfig>
						)}
					</ApiContext.Consumer>
				</ApiContextProvider>
			</UiStateContextProvider>
		</Auth0Provider>
	);
}

export default Providers;
