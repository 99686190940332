import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	Flex,
	Heading,
	Item,
	Picker,
	TextField,
} from "@adobe/react-spectrum";

import usePosTypes from "../hooks/usePosTypes";
import LanguageComboBox from "../components/LanguageComboBox";
import LuQueryFieldLabel from "../components/tutorials/lu/LuQueryFieldLabel";
import { setAutocapitalize } from "../utils/dom";

import AppBar from "../components/AppBar";

function LuSearchScreen() {
	const history = useHistory();

	const { data: posTags } = usePosTypes();

	const [queryText, setQueryText] = useState("");
	const [languageId, setlanguageId] = useState();
	const [posId, setPosId] = useState();

	const textRef = useRef();
	useEffect(() => setAutocapitalize(textRef), []);

	function submit() {
		history.push(
			`/search/results?q=${queryText}&lang=${languageId}&pos=${posId}`
		);
	}

	return (
		<Flex flex={1} direction="column">
			<AppBar/>
		<Flex flex={1} direction="column" gap="size-150" margin="size-200">
			<Heading level={3} margin="size-0">
				Search Lexical Unit
			</Heading>

			<TextField
				ref={textRef}
				label={<LuQueryFieldLabel />}
				value={queryText}
				onChange={setQueryText}
				validationState={queryText !== "" ? "valid" : null}
				width=""
			/>

			<LanguageComboBox
				label="In"
				labelPosition="side"
				onSelectionChange={setlanguageId}
			/>

			<Picker
				label="As a"
				labelPosition="side"
				onSelectionChange={setPosId}
				validationState="valid"
				items={posTags}
			>
				{(item) => <Item key={item.idPOS}>{item.name}</Item>}
			</Picker>

			<Button
				variant="cta"
				marginTop="size-150"
				onPress={submit}
				isDisabled={!(queryText && languageId && posId)}
			>
				SEARCH GLOBAL FRAMENET
			</Button>
		</Flex>
		</Flex>
	);
}

export default LuSearchScreen;
