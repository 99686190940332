import React from "react";
import {
	Button,
	ButtonGroup,
	Content,
	Dialog,
	Divider,
	Heading,
	Text,
} from "@adobe/react-spectrum";

function FeRelationHelpDialog({ close }) {
	return (
		<Dialog>
			<Heading>Requires, Excludes, and CoreSet relations in FN</Heading>
			<Divider />
			<Content>
				<Text>
					<p>Core FEs may be related to each other in three ways:</p>
					<p>
						One FE may REQUIRE that another one cooccurs with it in a sentence.
					</p>
					<p>
						One FE may EXCLUDE the possibility that another one occurs in the
						same sentence.
					</p>
					<p>
						Two or more FEs may form a CORESET, so that if one of them occurs in
						a sentence the other ones may or may not occur.
					</p>
				</Text>
			</Content>
			<ButtonGroup>
				<Button
					variant="secondary"
					onPress={() =>
						window.open("https://www.youtube.com/watch?v=VjR1LZ9q9f4", "_blank")
					}
				>
					Go to tutorial
				</Button>
				<Button variant="cta" onPress={close}>
					Got it!
				</Button>
			</ButtonGroup>
		</Dialog>
	);
}

export default FeRelationHelpDialog;
